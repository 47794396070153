import React from 'react';
import { Clock, AlertCircle, Bell, Camera, Flag, Calendar, ArrowUpDown, Package } from 'lucide-react';
import { TimelineEvent, EventType, OrderChange } from '../types/order';
import { axiosInstance } from '../api/client';
import { useAuthStore } from '../stores/authStore';

interface OrderTimelineProps {
  orderId: string;
}

const getEventIcon = (type: EventType) => {
  switch (type) {
    case EventType.STATUS_CHANGE:
      return <ArrowUpDown size={16} className="text-blue-600" />;
    case EventType.PRIORITY_CHANGE:
      return <Flag size={16} className="text-orange-600" />;
    case EventType.NOTE_ADDED:
      return <AlertCircle size={16} className="text-purple-600" />;
    case EventType.PHOTO_ADDED:
      return <Camera size={16} className="text-green-600" />;
    case EventType.SCHEDULED:
      return <Calendar size={16} className="text-indigo-600" />;
    case EventType.CUSTOMER_NOTIFIED:
      return <Bell size={16} className="text-yellow-600" />;
    case EventType.ORDER_UPDATED:
      return <Package size={16} className="text-emerald-600" />;
    default:
      return <Clock size={16} className="text-blue-600" />;
  }
};

const OrderTimeline: React.FC<OrderTimelineProps> = ({ orderId }) => {
  const [events, setEvents] = React.useState<TimelineEvent[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const [retryCount, setRetryCount] = React.useState(0);
  const { isAuthenticated } = useAuthStore();

  React.useEffect(() => {
    const fetchTimeline = async () => {
      if (!isAuthenticated) {
        console.debug('Skipping timeline fetch - not authenticated');
        return;
      }

      try {
        setLoading(true);
        setError(null);
        console.debug('Fetching timeline:', {
          orderId,
          hasToken: !!localStorage.getItem('token'),
          retryCount
        });
        
        const response = await axiosInstance.get(`/timeline/orders/${orderId}`);
        console.debug('Timeline response:', response.data);
        
        // Handle the success response structure
        const timelineData = response.data.data || [];
        // Set events with most recent first
        setEvents(timelineData);
      } catch (err: any) {
        console.error('Timeline fetch error:', {
          status: err.response?.status,
          message: err.message,
          data: err.response?.data
        });
        
        if (err.response?.status === 401 && retryCount < 2) {
          // If unauthorized and haven't retried too many times, increment retry counter
          setRetryCount(prev => prev + 1);
          return;
        }
        
        setError(err.response?.data?.message || 'Failed to load timeline');
      } finally {
        setLoading(false);
      }
    };

    if (orderId) {
      fetchTimeline();
    }
  }, [orderId, isAuthenticated, retryCount]);

  const formatDate = (dateString: string) => {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(new Date(dateString));
  };

  if (loading) return <div className="text-center py-4">Loading timeline...</div>;
  if (error) return <div className="text-center text-red-500 py-4">{error}</div>;
  if (events.length === 0) return <div className="text-center text-gray-500 py-4">No timeline events found</div>;

  return (
    <div className="space-y-4">
      {events.map((event, index) => (
        <div key={event.id} className="relative">
          {/* Line connector */}
          {index !== events.length - 1 && (
            <div className="absolute left-2.5 top-6 bottom-0 w-px bg-gray-200" />
          )}
          
          <div className="flex items-start gap-4">
            {/* Icon */}
            <div className={`mt-1 rounded-full p-1 ${
              event.type === EventType.STATUS_CHANGE ? 'bg-blue-100' :
              event.type === EventType.PRIORITY_CHANGE ? 'bg-orange-100' :
              event.type === EventType.NOTE_ADDED ? 'bg-purple-100' :
              event.type === EventType.PHOTO_ADDED ? 'bg-green-100' :
              event.type === EventType.SCHEDULED ? 'bg-indigo-100' :
              event.type === EventType.CUSTOMER_NOTIFIED ? 'bg-yellow-100' :
              event.type === EventType.ORDER_UPDATED ? 'bg-emerald-100' :
              'bg-blue-100'
            }`}>
              {getEventIcon(event.type)}
            </div>

            {/* Content */}
            <div className="flex-1 bg-gray-50 rounded-lg p-3">
              <div className="text-sm font-medium text-gray-900 mb-1">
                {event.description}
              </div>
              <div className="text-xs text-gray-500 flex items-center gap-2">
                <span>{formatDate(event.createdAt)}</span>
                <span>•</span>
                <span>by {event.createdBy}</span>
              </div>
              {event.metadata && Object.keys(event.metadata).length > 0 && (
                <div className="mt-2 text-xs text-gray-600">
                  {event.type === EventType.STATUS_CHANGE && event.metadata.previousStatus && (
                    <div>
                      Previous Status: {event.metadata.previousStatus}
                    </div>
                  )}
                  {event.type === EventType.PRIORITY_CHANGE && event.metadata.previousPriority && (
                    <div>
                      Previous Priority: {event.metadata.previousPriority}
                    </div>
                  )}
                  {event.type === EventType.SCHEDULED && event.metadata.scheduledDate && (
                    <div>
                      Scheduled for: {new Date(event.metadata.scheduledDate).toLocaleDateString()}
                    </div>
                  )}
                  {event.type === EventType.CUSTOMER_NOTIFIED && event.metadata.notificationType && (
                    <div>
                      Notification Type: {event.metadata.notificationType}
                    </div>
                  )}
                  {event.type === EventType.ORDER_UPDATED && (
                    <div className="space-y-1">
                      {event.metadata.itemsRemoved && (
                        <div className="text-red-600">
                          Items were removed from this order
                        </div>
                      )}
                      {event.metadata.changes?.some((change: OrderChange) => change.oldValue === null) && (
                        <div className="text-emerald-600">
                          New items were added to this order
                        </div>
                      )}
                      {event.metadata.changes?.some((change: OrderChange) => 
                        change.oldValue !== null && 
                        change.newValue !== null && 
                        change.field.includes('quantity')
                      ) && (
                        <div className="text-blue-600">
                          Item quantities were modified in this order
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OrderTimeline;
