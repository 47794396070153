// src/components/picklist/PickListSummary.tsx
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { PickListSummaryProps } from '@/types/picklist';
import { OrderStatus } from '@/types/order';
import { Package } from 'lucide-react';

const PickListSummary = ({ orders }: PickListSummaryProps) => {
  // Calculate production summary from orders
  const productionSummary = orders.reduce((acc: Record<string, number>, order) => {
    // Only include orders that aren't completed, cancelled, or deleted
    if (!order.completedAt && 
        order.order.status !== OrderStatus.CANCELLED && 
        !order.order.deletedAt) {
      order.order.items.forEach(item => {
        const itemName = item.variantTitle 
          ? `${item.productTitle} (${item.variantTitle})`
          : item.productTitle;
        
        if (!acc[itemName]) {
          acc[itemName] = 0;
        }
        acc[itemName] += item.quantity;
      });
    }
    return acc;
  }, {});

  return (
    <Card className="mb-6">
      <CardHeader>
        <CardTitle className="text-xl font-semibold text-gray-800">
          Production Summary
        </CardTitle>
      </CardHeader>
      <CardContent>
        {Object.keys(productionSummary).length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {Object.entries(productionSummary).map(([name, quantity]) => (
              <div
                key={name}
                className="flex items-center p-4 bg-white border border-gray-200 rounded-lg shadow-sm hover:shadow-md transition-shadow"
              >
                <div className="flex-shrink-0">
                  <Package className="w-10 h-10 text-blue-600" />
                </div>
                <div className="ml-4">
                  <div className="text-lg font-medium text-gray-900">{name}</div>
                  <div className="text-2xl font-bold text-blue-600">{quantity}</div>
                  <div className="text-sm text-gray-500">Units to produce</div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-6 text-gray-500">
            No items to produce
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default PickListSummary;
