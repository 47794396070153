import React, { useState, useEffect } from 'react';
import { X, Calendar, Package, User, Mail, Clock, History, Edit2, Trash2, Plus } from 'lucide-react';
import { Order, OrderDetailsModalProps, OrderPriority, OrderItem } from '../types/order';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import OrderTimeline from './OrderTimeline';
import { useAuthStore } from '../stores/authStore';
import { updateOrderPriority, updateOrderItems, deleteOrder, getUniqueProducts } from '../api/orders';

interface EditableOrderDetailsModalProps extends OrderDetailsModalProps {
  onOrderUpdate: () => void;
}

const EditableOrderDetailsModal: React.FC<EditableOrderDetailsModalProps> = ({ order, onClose, onOrderUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedOrder, setEditedOrder] = useState<Order | null>(order);
  const [availableProducts, setAvailableProducts] = useState<Array<{
    productId: string;
    productTitle: string;
    variantId: string | null;
    variantTitle: string | null;
  }>>([]);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showAddItem, setShowAddItem] = useState(false);
  const [newItem, setNewItem] = useState<{
    productId: string;
    quantity: number;
  }>({ productId: '', quantity: 1 });
  
  const { user } = useAuthStore();
  const canEdit = user?.role === 'operator' || user?.role === 'admin';

  useEffect(() => {
    if (canEdit && isEditing) {
      fetchAvailableProducts();
    }
  }, [canEdit, isEditing]);

  const fetchAvailableProducts = async () => {
    try {
      setIsLoadingProducts(true);
      const products = await getUniqueProducts();
      setAvailableProducts(products.data);
    } catch (error) {
      console.error('Failed to fetch products:', error);
    } finally {
      setIsLoadingProducts(false);
    }
  };

  const handlePriorityChange = async (priority: OrderPriority) => {
    if (!editedOrder || !user?.id) return;
    try {
      const updatedOrder = await updateOrderPriority(editedOrder.id, priority);
      // Keep the existing customer data and items since they're not changing
      setEditedOrder({
        ...updatedOrder,
        customer: editedOrder.customer,
        items: editedOrder.items
      });
      onOrderUpdate();
    } catch (error) {
      console.error('Failed to update priority:', error);
    }
  };

  const handleQuantityChange = (itemIndex: number, quantity: number) => {
    if (!editedOrder) return;
    const items = editedOrder.items || [];
    const newItems = [...items];
    newItems[itemIndex] = { ...newItems[itemIndex], quantity };
    setEditedOrder({ ...editedOrder, items: newItems });
  };

  const handleRemoveItem = (itemIndex: number) => {
    if (!editedOrder) return;
    const items = editedOrder.items || [];
    const newItems = items.filter((_, index) => index !== itemIndex);
    setEditedOrder({ ...editedOrder, items: newItems });
  };

  const handleAddItem = () => {
    if (!editedOrder || !newItem.productId) return;
    const product = availableProducts.find(p => p.productId === newItem.productId);
    if (!product) return;

    const newOrderItem: OrderItem = {
      productId: product.productId,
      productTitle: product.productTitle,
      variantTitle: product.variantTitle || undefined,
      quantity: newItem.quantity
    };

    setEditedOrder({
      ...editedOrder,
      items: [...(editedOrder.items || []), newOrderItem]
    });
    setNewItem({ productId: '', quantity: 1 });
    setShowAddItem(false);
  };

  const handleSaveChanges = async () => {
    if (!editedOrder || !user?.id) return;
    try {
      await updateOrderItems(editedOrder.id, user.id, editedOrder.items);
      onOrderUpdate();
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to update items:', error);
    }
  };

  const handleDeleteOrder = async () => {
    if (!editedOrder || !canEdit) return;
    setIsDeleting(true);
    try {
      await deleteOrder(editedOrder.id);
      onOrderUpdate();
      onClose();
    } catch (error) {
      console.error('Failed to mark order as deleted:', error);
    } finally {
      setIsDeleting(false);
    }
  };

  if (!editedOrder) return null;

  const formatDate = (date: string | Date) => {
    if (!date) return 'No date available';
    
    try {
      const dateObj = date instanceof Date ? date : new Date(date);
      if (isNaN(dateObj.getTime())) {
        return 'Invalid date';
      }
      
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }).format(dateObj);
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };

  const getPriorityColor = (priority: Order['priority']) => {
    switch (priority) {
      case 'URGENT':
        return 'bg-red-100 text-red-800';
      case 'HIGH':
        return 'bg-orange-100 text-orange-800';
      case 'NORMAL':
        return 'bg-blue-100 text-blue-800';
      case 'LOW':
        return 'bg-gray-100 text-gray-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg w-full max-w-2xl h-[90vh] flex flex-col">
        {/* Header */}
        <div className="px-6 py-4 border-b border-gray-200 flex-shrink-0">
          <div className="flex flex-col space-y-3">
            <div className="flex items-center justify-between">
              <h2 className="text-xl font-semibold">Order #{editedOrder.orderNumber}</h2>
              <div className="flex items-center">
                <button onClick={onClose} className="text-gray-500 hover:text-gray-700 p-2">
                  <X size={20} />
                </button>
              </div>
            </div>
            {canEdit && (
              <div className="flex items-center space-x-4">
                {!isEditing && (
                  <button
                    onClick={() => setIsEditing(true)}
                    className="flex items-center px-3 py-1.5 text-blue-600 hover:text-blue-700 hover:bg-blue-50 rounded-md transition-colors"
                  >
                    <Edit2 size={16} className="mr-1.5" />
                    <span>Edit Order</span>
                  </button>
                )}
                <Select
                  value={editedOrder.priority}
                  onValueChange={(value: string) => handlePriorityChange(value as OrderPriority)}
                >
                  <SelectTrigger className={`w-32 ${getPriorityColor(editedOrder.priority)}`}>
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.values(OrderPriority).map((priority) => (
                      <SelectItem key={priority} value={priority}>
                        {priority}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            )}
          </div>
        </div>

        {/* Content */}
        <div className="p-6 overflow-y-auto flex-1">
          {/* Order Details */}
          <div className="grid grid-cols-2 gap-6 mb-6">
            <div className="space-y-4">
              <h3 className="font-medium text-gray-900">Order Information</h3>
              <div className="space-y-3">
                <div className="flex items-center text-sm text-gray-600">
                  <Calendar size={16} className="mr-2" />
                  Created: {formatDate(editedOrder.createdAt)}
                </div>
                <div className="flex items-center text-sm text-gray-600">
                  <Clock size={16} className="mr-2" />
                  Status: {editedOrder.status}
                </div>
              </div>
            </div>

            <div className="space-y-4">
              <h3 className="font-medium text-gray-900">Customer Details</h3>
              <div className="space-y-3">
                <div className="flex items-center text-sm text-gray-600">
                  <User size={16} className="mr-2" />
                  <div className="flex flex-col">
                    <span>{editedOrder.customer?.name || 'No Name'}</span>
                    {editedOrder.customer?.customerType && (
                      <span className={`text-xs px-2 py-0.5 rounded-full mt-1 inline-block w-fit ${editedOrder.customer.customerType === 'BUSINESS' ? 'bg-purple-100 text-purple-800' : 'bg-blue-100 text-blue-800'}`}>
                        {editedOrder.customer.customerType === 'BUSINESS' ? 'Business' : 'Individual'}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex items-center text-sm text-gray-600">
                  <Mail size={16} className="mr-2" />
                  {editedOrder.customer?.email || 'No Email'}
                </div>
              </div>
            </div>
          </div>

          {/* Order Items */}
          <div className="mb-6">
            <div className="flex items-center justify-between mb-4">
              <h3 className="font-medium text-gray-900">Order Items</h3>
              {canEdit && isEditing && (
                <button
                  onClick={() => setShowAddItem(true)}
                  className="px-3 py-1.5 bg-blue-50 text-blue-600 hover:bg-blue-100 rounded-md transition-colors flex items-center"
                >
                  <Plus size={18} className="mr-1.5" />
                  Add New Item
                </button>
              )}
            </div>

            <div className="space-y-3">
              {(editedOrder.items || []).map((item, index) => (
                <div key={index} className="bg-gray-50 p-4 rounded-lg border border-gray-200 hover:border-gray-300 transition-colors">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center flex-1">
                      <Package size={18} className="mr-3 text-gray-500" />
                      <div>
                        <div className="font-medium text-gray-900">{item.productTitle}</div>
                        {item.variantTitle && (
                          <div className="text-sm text-gray-500">{item.variantTitle}</div>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      {isEditing ? (
                        <div className="flex items-center space-x-3">
                          <div className="flex items-center">
                            <button
                              onClick={() => handleQuantityChange(index, Math.max(1, item.quantity - 1))}
                              className="px-2 py-1 border border-gray-300 rounded-l hover:bg-gray-100"
                            >
                              -
                            </button>
                            <input
                              type="number"
                              min="1"
                              value={item.quantity}
                              onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))}
                              className="w-16 px-2 py-1 border-t border-b border-gray-300 text-center"
                            />
                            <button
                              onClick={() => handleQuantityChange(index, item.quantity + 1)}
                              className="px-2 py-1 border border-gray-300 rounded-r hover:bg-gray-100"
                            >
                              +
                            </button>
                          </div>
                          <button
                            onClick={() => handleRemoveItem(index)}
                            className="p-1.5 text-red-600 hover:text-red-700 hover:bg-red-50 rounded transition-colors"
                          >
                            <Trash2 size={18} />
                          </button>
                        </div>
                      ) : (
                        <span className="px-3 py-1 bg-gray-100 rounded-full text-gray-700">
                          Qty: {item.quantity}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              ))}

              {showAddItem && (
                <div className="bg-blue-50 border border-blue-100 p-4 rounded-lg mb-4">
                  <div className="space-y-4">
                    <div className="flex items-center justify-between mb-2">
                      <h4 className="font-medium text-blue-900">Add New Item</h4>
                      <button
                        onClick={() => setShowAddItem(false)}
                        className="text-blue-600 hover:text-blue-800"
                      >
                        <X size={16} />
                      </button>
                    </div>
                    {isLoadingProducts ? (
                      <div className="flex justify-center py-4">
                        <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-600"></div>
                      </div>
                    ) : (
                      <>
                        <div className="flex gap-3">
                          <Select
                            value={newItem.productId}
                            onValueChange={(value: string) => setNewItem({ ...newItem, productId: value })}
                          >
                            <SelectTrigger className="flex-1">
                              <SelectValue placeholder="Select product" />
                            </SelectTrigger>
                            <SelectContent>
                              {Array.isArray(availableProducts) && availableProducts.map((product) => (
                                <SelectItem key={product.productId} value={product.productId}>
                                  {product.productTitle}
                                  {product.variantTitle ? ` (${product.variantTitle})` : ''}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </div>
                        <div className="flex items-center space-x-4">
                          <div className="flex items-center">
                            <label className="mr-3 text-sm text-blue-800">Quantity:</label>
                            <div className="flex items-center">
                              <button
                                onClick={() => setNewItem({ ...newItem, quantity: Math.max(1, newItem.quantity - 1) })}
                                className="px-2 py-1 border border-blue-200 rounded-l hover:bg-blue-100"
                              >
                                -
                              </button>
                              <input
                                type="number"
                                min="1"
                                value={newItem.quantity}
                                onChange={(e) => setNewItem({ ...newItem, quantity: parseInt(e.target.value) })}
                                className="w-16 px-2 py-1 border-t border-b border-blue-200 text-center"
                              />
                              <button
                                onClick={() => setNewItem({ ...newItem, quantity: newItem.quantity + 1 })}
                                className="px-2 py-1 border border-blue-200 rounded-r hover:bg-blue-100"
                              >
                                +
                              </button>
                            </div>
                          </div>
                          <button
                            onClick={handleAddItem}
                            disabled={!newItem.productId}
                            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:bg-blue-300 disabled:cursor-not-allowed transition-colors flex items-center"
                          >
                            <Plus size={16} className="mr-1.5" />
                            Add to Order
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Timeline */}
          <div className="mb-6">
            <div className="flex items-center gap-2 mb-4">
              <History size={18} className="text-gray-700" />
              <h3 className="font-medium text-gray-900">Order Timeline</h3>
            </div>
            <OrderTimeline orderId={editedOrder.id} />
          </div>
        </div>

        {/* Footer */}
        <div className="px-6 py-4 border-t border-gray-200 flex-shrink-0 bg-white">
          <div className="flex justify-between items-center">
            {canEdit && !isEditing && (
              <button
                onClick={handleDeleteOrder}
                disabled={isDeleting}
                className="flex items-center px-3 py-2 text-red-600 hover:text-red-100 hover:bg-red-600 rounded-md transition-colors"
                title="Mark order as deleted (recoverable)"
              >
                <Trash2 size={18} className="mr-1.5" />
                {isDeleting ? (
                  <>
                    <span>Deleting</span>
                    <div className="ml-2 animate-spin rounded-full h-4 w-4 border-2 border-red-100 border-t-transparent" />
                  </>
                ) : (
                  <span>Delete Order</span>
                )}
              </button>
            )}
            <div className="flex space-x-4">
              {isEditing && (
                <>
                  <button
                    onClick={() => {
                      setIsEditing(false);
                      setEditedOrder(order);
                    }}
                    className="px-4 py-2 text-gray-600 hover:text-gray-800"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSaveChanges}
                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors flex items-center"
                  >
                    <span className="mr-1.5">Save Changes</span>
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                  </button>
                </>
              )}
              {!isEditing && (
                <button
                  onClick={onClose}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800"
                >
                  Close
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditableOrderDetailsModal;
