import React, { useState, useEffect } from 'react';
import { Customer } from '../types/customer';
import { getCustomers } from '../api/customers';
import EditableCustomerModal from '../components/EditableCustomerModal';
import { Star } from 'lucide-react';

// Reuse SearchBar component from OrderHistoryPage
const SearchBar = React.memo(({ onSearch, initialSearchTerm = '' }: { 
  onSearch: (term: string | undefined) => void;
  initialSearchTerm?: string;
}) => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

  useEffect(() => {
    setSearchTerm(initialSearchTerm);
  }, [initialSearchTerm]);
  
  const handleSearch = () => {
    onSearch(searchTerm || undefined);
  };

  const handleReset = () => {
    setSearchTerm('');
    onSearch(undefined);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="flex gap-2">
      <input
        type="text"
        placeholder="Search customers..."
        className="px-3 py-2 border rounded-md w-full max-w-md"
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyPress={handleKeyPress}
        value={searchTerm}
      />
      <button
        onClick={handleSearch}
        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
      >
        Search
      </button>
      {searchTerm && (
        <button
          onClick={handleReset}
          className="px-4 py-2 bg-gray-100 text-gray-600 rounded-md hover:bg-gray-200 transition-colors"
        >
          Reset
        </button>
      )}
    </div>
  );
});

function CustomersPage() {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      setLoading(true);
      const data = await getCustomers();
      setCustomers(data);
      setError('');
    } catch (err) {
      setError('Failed to load customers');
      console.error('Error fetching customers:', err);
    } finally {
      setLoading(false);
    }
  };

  const filteredCustomers = customers.filter(customer => 
    customer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    customer.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  if (error && customers.length === 0) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-lg text-red-500">{error}</div>
      </div>
    );
  }

  return (
    <div className="p-4 h-full">
      <div className="space-y-6 mb-6">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
          <SearchBar 
            initialSearchTerm={searchTerm}
            onSearch={(term) => setSearchTerm(term || '')}
          />
          <div className="text-sm text-gray-600 whitespace-nowrap">
            Showing {filteredCustomers.length} customers
          </div>
        </div>
      </div>

      <div 
        className="overflow-auto rounded-lg border border-gray-200"
        style={{ height: 'calc(100vh - 220px)' }}
      >
        <table className="min-w-full bg-white">
          <thead className="bg-gray-50 sticky top-0">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Orders</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {filteredCustomers.map(customer => (
              <tr 
                key={customer.id}
                onClick={() => setSelectedCustomer(customer)}
                className="hover:bg-gray-50 cursor-pointer"
              >
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="text-sm font-medium text-gray-900">
                      {customer.name}
                      {customer.isVIP && (
                        <Star className="inline ml-1 text-yellow-400" size={16} />
                      )}
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-500">{customer.email}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 py-1 text-xs rounded-full ${
                    customer.isVIP
                      ? 'bg-yellow-100 text-yellow-800'
                      : (customer.activeOrderCount || 0) > 0
                      ? 'bg-green-100 text-green-800'
                      : 'bg-gray-100 text-gray-800'
                  }`}>
                    {customer.isVIP ? 'VIP' : (customer.activeOrderCount || 0) > 0 ? 'Active' : 'New'}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {customer.activeOrderCount || 0} orders
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {formatDate(customer.createdAt)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {loading && (
          <div className="flex justify-center p-4">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
          </div>
        )}
      </div>

      {selectedCustomer && (
        <EditableCustomerModal
          customer={selectedCustomer}
          onClose={() => setSelectedCustomer(null)}
          onCustomerUpdate={fetchCustomers}
        />
      )}
    </div>
  );
}

export default CustomersPage;
