import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from '../components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Loader, CheckCircle, AlertCircle } from 'lucide-react';
import { useToast } from '../components/ui/use-toast';
import { getOrderById } from '../api/orders';
import { axiosInstance } from '../api/client';
import { PickListStatus } from '../types/order';
import type { Order, OrderItem } from '../types/order';

const VerifyOrderPage = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();

  const [orderData, setOrderData] = useState<Order | null>(null);
  const [pickListId, setPickListId] = useState<string | null>(null);
  const [verifiedItems, setVerifiedItems] = useState<boolean[]>([]);
  const [loading, setLoading] = useState(true);
  const [verifying, setVerifying] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [orderVerified, setOrderVerified] = useState(false);
  const [verificationWarning, setVerificationWarning] = useState<string | null>(null);
  const [verificationError, setVerificationError] = useState<string | null>(null);
  const [verifierName, setVerifierName] = useState<string | null>(null);

  const allItemsVerified = verifiedItems.every(Boolean);

  // Check if order is already verified
  const isAlreadyVerified = Boolean(orderData?.verifiedAt || orderData?.verifiedBy);

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        setLoading(true);
        if (!orderId) {
          throw new Error('Order ID is required');
        }
        const order = await getOrderById(orderId);
        setOrderData(order);

        // If order is already verified, set appropriate state
        if (order.verifiedAt || order.verifiedBy) {
          setOrderVerified(true);
          const verificationTime = order.verifiedAt ? new Date(order.verifiedAt).toLocaleString() : 'unknown time';
          if (order.verifiedBy) {
            setVerifierName(order.verifiedBy);
            setVerificationError(`This order was already verified by ${order.verifiedBy} on ${verificationTime}`);
          } else {
            setVerificationError(`This order was already verified on ${verificationTime}`);
          }
        } else {
          const activePickList = order.pickListOrders?.find(
            (plo) => plo.pickList?.status === PickListStatus.ACTIVE
          );

          if (!activePickList?.pickList?.id) {
            setError('No active pick list found for this order');
            return;
          }

          setPickListId(activePickList.pickList.id);
          setVerifiedItems(new Array(order.items.length).fill(false));
        }
      } catch (err) {
        console.error('Error fetching order:', err);
        setError('Failed to load order details');
        toast({
          variant: "destructive",
          title: "Error",
          description: "Could not load order details",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchOrderData();
  }, [orderId, toast]);

  const handleVerify = async () => {
    if (!allItemsVerified) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Please verify all items before proceeding",
      });
      return;
    }

    if (!pickListId || !orderData) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "No active pick list found for this order",
      });
      return;
    }

    try {
      setVerifying(true);

      const verifiedProductIds = verifiedItems
        .map((isVerified: boolean, index: number) => (isVerified ? orderData?.items[index]?.productId : null))
        .filter(Boolean);

      const response = await axiosInstance.post(`/orders/${orderData.id}/verify`, {
        pickListId,
        verifiedItems: verifiedProductIds,
        notes: 'All items verified and packed'
      });

      const { message, warning, verifiedBy } = response.data;

      // Show success toast
      toast({
        title: "Success",
        description: message,
      });

      // If there's a warning (e.g., email not sent), show it as a separate toast
      if (warning) {
        setVerificationWarning(warning);
        toast({
          variant: "destructive",
          title: "Warning",
          description: warning,
        });
      }

      // Set verifier name directly from response
      if (verifiedBy) {
        setVerifierName(verifiedBy);
      }

      setOrderVerified(true);
    } catch (err: any) {
      // Get error message from the standardized error response
      const errorResponse = err.response?.data;
      const errorMessage = errorResponse?.message || "Failed to verify order";
      
      toast({
        variant: "destructive",
        title: "Verification Failed",
        description: errorMessage,
      });
    } finally {
      setVerifying(false);
    }
  };

  const toggleCheckbox = (index: number) => {
    setVerifiedItems((prev: boolean[]) =>
      prev.map((isVerified: boolean, idx: number) => (idx === index ? !isVerified : isVerified))
    );
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader className="w-6 h-6 animate-spin" />
      </div>
    );
  }

  if (error || !orderData) {
    return (
      <Card className="mx-auto mt-4 max-w-md">
        <CardContent className="p-6 text-center">
          <AlertCircle className="w-12 h-12 mx-auto text-red-500 mb-4" />
          <p className="text-lg font-semibold">Error loading order</p>
          <p className="text-gray-500 mt-2">{error}</p>
          <Button onClick={() => navigate(-1)} className="mt-4">
            Go Back
          </Button>
        </CardContent>
      </Card>
    );
  }

  if (orderVerified) {
    return (
      <div className="min-h-screen bg-gray-50 py-4 px-4 flex flex-col items-center justify-center">
        <Card className="max-w-md w-full">
          <CardHeader>
            <CardTitle className="text-center">
              {verificationError ? 'Order Already Verified' : 'Order Verified Successfully'}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-center mb-6">
              <div className={verificationError ? 'text-yellow-600' : 'text-green-600'}>
                {verificationError ? (
                  <AlertCircle className="w-12 h-12 mx-auto mb-2" />
                ) : (
                  <CheckCircle className="w-12 h-12 mx-auto mb-2" />
                )}
                <p className="text-lg font-medium">
                  {verificationError || `Order #${orderData?.orderNumber} has been verified${verifierName ? ` by ${verifierName}` : ''}`}
                </p>
              </div>
              {verificationWarning && !verificationError && (
                <div className="mt-4 p-3 bg-yellow-50 border border-yellow-200 rounded-md">
                  <p className="text-yellow-700 text-sm">{verificationWarning}</p>
                </div>
              )}
            </div>
            <Button
              className="w-full"
              size="lg"
              onClick={() => navigate(-1)}
            >
              Return to Orders
            </Button>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-4 px-4 flex flex-col items-center">
      <Card className="max-w-md w-full">
        <CardHeader>
          <CardTitle>Verify Order #{orderData.orderNumber}</CardTitle>
          <div className="text-sm text-gray-600 mt-2 flex items-center gap-2">
            <span>Customer: {orderData.customer?.name || 'No Name'}</span>
            {orderData.customer?.customerType && (
              <span className={`px-2 py-0.5 rounded-full text-xs ${orderData.customer.customerType === 'BUSINESS' ? 'bg-purple-100 text-purple-800' : 'bg-blue-100 text-blue-800'}`}>
                {orderData.customer.customerType === 'BUSINESS' ? 'Business' : 'Individual'}
              </span>
            )}
            <span className="text-blue-600">
              ({orderData.customer?.orderCount || 0} previous orders)
            </span>
          </div>
        </CardHeader>
        <CardContent>
          <div className="mb-4 text-sm text-gray-500">
            Total Items: {orderData.items.length}
          </div>
          <div className="space-y-4 mb-6">
            {orderData.items.map((item: OrderItem, index: number) => (
              <div
                key={item.productId}
                className={`p-4 rounded-lg border ${
                  verifiedItems[index]
                    ? 'bg-green-50 border-green-200'
                    : 'bg-white border-gray-200'
                }`}
              >
                <div className="flex items-start gap-4">
                  <div className="flex-1">
                    <div className="font-medium">{item.productTitle}</div>
                    {item.variantTitle && (
                      <div className="text-sm text-gray-500">
                        {item.variantTitle}
                      </div>
                    )}
                    <div className="text-sm font-medium mt-1">
                      Quantity: {item.quantity}
                    </div>
                  </div>
                  <label
                    htmlFor={`verify-item-${index}`}
                    className="flex items-center justify-center w-8 h-8 cursor-pointer"
                  >
                    <input
                      id={`verify-item-${index}`}
                      type="checkbox"
                      checked={verifiedItems[index]}
                      onChange={() => toggleCheckbox(index)}
                      className="w-6 h-6 rounded-full cursor-pointer"
                      aria-label={`Verify ${item.productTitle}`}
                    />
                  </label>
                </div>
              </div>
            ))}
          </div>

          <Button
            className="w-full"
            size="lg"
            disabled={!allItemsVerified || verifying || isAlreadyVerified}
            onClick={handleVerify}
          >
            {verifying ? (
              <Loader className="w-4 h-4 mr-2 animate-spin" />
            ) : (
              <CheckCircle className="w-4 h-4 mr-2" />
            )}
            {verifying ? 'Verifying...' : 'Confirm Order Packed'}
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default VerifyOrderPage;
