import { useState } from 'react';
import { X, Star, Edit2, Mail, User, Building2 } from 'lucide-react';
import { Customer, CustomerType, CustomerUpdateInput } from '../types/customer';
import AddressManager from './address/AddressManager';
import { Order, OrderStatus } from '../types/order';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../stores/authStore';
import { updateCustomer } from '../api/customers';

interface EditableCustomerModalProps {
  customer: Customer;
  onClose: () => void;
  onCustomerUpdate: () => void;
}

const OrderStatusBadge = ({ status }: { status: OrderStatus }) => {
  const statusColors = {
    [OrderStatus.PLACED]: 'bg-blue-100 text-blue-800',
    [OrderStatus.IN_PRODUCTION]: 'bg-yellow-100 text-yellow-800',
    [OrderStatus.PACKED]: 'bg-purple-100 text-purple-800',
    [OrderStatus.READY_TO_SHIP]: 'bg-indigo-100 text-indigo-800',
    [OrderStatus.SHIPPED]: 'bg-green-100 text-green-800',
    [OrderStatus.CANCELLED]: 'bg-red-100 text-red-800',
  };

  return (
    <span className={`px-2 py-1 text-xs rounded-full ${statusColors[status]}`}>
      {status.replace('_', ' ')}
    </span>
  );
};

export default function EditableCustomerModal({ customer, onClose, onCustomerUpdate }: EditableCustomerModalProps) {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const canEdit = user?.role === 'operator' || user?.role === 'admin';

  const [isEditing, setIsEditing] = useState(false);
  const [editedCustomer, setEditedCustomer] = useState<Customer>(customer);
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState<string | null>(null);

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const handleOrderClick = (order: Order) => {
    navigate(`/orders?orderId=${order.id}`);
  };

  const handleSaveChanges = async () => {
    try {
      setIsSaving(true);
      setSaveError(null);
      
      const updateData: CustomerUpdateInput = {
        email: editedCustomer.email,
        customerType: editedCustomer.customerType,
        isVIP: editedCustomer.isVIP,
        ...(editedCustomer.customerType === CustomerType.INDIVIDUAL
          ? {
              firstName: editedCustomer.firstName,
              lastName: editedCustomer.lastName,
              businessName: undefined,
              abn: undefined
            }
          : {
              firstName: undefined,
              lastName: undefined,
              businessName: editedCustomer.businessName,
              abn: editedCustomer.abn
            })
      };

      await updateCustomer(editedCustomer.id, updateData);
      onCustomerUpdate();
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to update customer:', error);
      setSaveError('Failed to save changes. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const getDisplayName = (customer: Customer) => {
    if (customer.customerType === CustomerType.BUSINESS) {
      return customer.businessName || customer.name;
    }
    return customer.firstName && customer.lastName 
      ? `${customer.firstName} ${customer.lastName}`
      : customer.name;
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-3xl max-h-[90vh] flex flex-col">
        {/* Header */}
        <div className="p-6 border-b">
          <div className="flex justify-between items-start">
            <div className="space-y-4">
              {isEditing ? (
                <div className="space-y-4">
                  <div className="flex items-center space-x-2 mb-4">
                    <select
                      value={editedCustomer.customerType}
                      onChange={(e) => setEditedCustomer({ 
                        ...editedCustomer, 
                        customerType: e.target.value as CustomerType 
                      })}
                      className="border rounded-md px-3 py-1.5"
                    >
                      <option value={CustomerType.INDIVIDUAL}>Individual</option>
                      <option value={CustomerType.BUSINESS}>Business</option>
                    </select>
                  </div>

                  {editedCustomer.customerType === CustomerType.INDIVIDUAL ? (
                    <div className="space-y-4">
                      <div className="flex items-center space-x-2">
                        <User size={20} className="text-gray-500" />
                        <input
                          type="text"
                          value={editedCustomer.firstName || ''}
                          onChange={(e) => setEditedCustomer({ 
                            ...editedCustomer, 
                            firstName: e.target.value 
                          })}
                          className="border rounded-md px-3 py-1.5 w-64"
                          placeholder="First Name"
                        />
                      </div>
                      <div className="flex items-center space-x-2">
                        <User size={20} className="text-gray-500" />
                        <input
                          type="text"
                          value={editedCustomer.lastName || ''}
                          onChange={(e) => setEditedCustomer({ 
                            ...editedCustomer, 
                            lastName: e.target.value 
                          })}
                          className="border rounded-md px-3 py-1.5 w-64"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="space-y-4">
                      <div className="flex items-center space-x-2">
                        <Building2 size={20} className="text-gray-500" />
                        <input
                          type="text"
                          value={editedCustomer.businessName || ''}
                          onChange={(e) => setEditedCustomer({ 
                            ...editedCustomer, 
                            businessName: e.target.value 
                          })}
                          className="border rounded-md px-3 py-1.5 w-64"
                          placeholder="Business Name"
                        />
                      </div>
                      <div className="flex items-center space-x-2">
                        <Building2 size={20} className="text-gray-500" />
                        <input
                          type="text"
                          value={editedCustomer.abn || ''}
                          onChange={(e) => setEditedCustomer({ 
                            ...editedCustomer, 
                            abn: e.target.value 
                          })}
                          className="border rounded-md px-3 py-1.5 w-64"
                          placeholder="ABN"
                        />
                      </div>
                    </div>
                  )}

                  <div className="flex items-center space-x-2">
                    <Mail size={20} className="text-gray-500" />
                    <input
                      type="email"
                      value={editedCustomer.email}
                      onChange={(e) => setEditedCustomer({ 
                        ...editedCustomer, 
                        email: e.target.value 
                      })}
                      className="border rounded-md px-3 py-1.5 w-64"
                      placeholder="Email Address"
                    />
                  </div>
                  <div className="flex items-center space-x-2">
                    <Star size={20} className="text-gray-500" />
                    <label className="flex items-center space-x-2 cursor-pointer">
                      <input
                        type="checkbox"
                        checked={editedCustomer.isVIP}
                        onChange={(e) => setEditedCustomer({ ...editedCustomer, isVIP: e.target.checked })}
                        className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                      <span className="text-sm text-gray-700">VIP Customer</span>
                    </label>
                  </div>
                </div>
              ) : (
                <>
                  <h2 className="text-2xl font-semibold flex items-center gap-2">
                    {getDisplayName(editedCustomer)}
                    {editedCustomer.isVIP && (
                      <Star className="text-yellow-400" size={20} />
                    )}
                  </h2>
                  <p className="text-gray-600">{editedCustomer.email}</p>
                  <div className="flex items-center gap-3">
                    <span className="text-sm text-gray-500">
                      {editedCustomer.orderCount} orders
                    </span>
                    {editedCustomer.isVIP && (
                      <span className="px-2 py-1 text-xs bg-yellow-100 text-yellow-800 rounded-full">
                        VIP Customer
                      </span>
                    )}
                    <span className="text-sm text-gray-500">
                      Customer since {formatDate(editedCustomer.createdAt)}
                    </span>
                  </div>
                </>
              )}
              {canEdit && !isEditing && (
                <button
                  onClick={() => setIsEditing(true)}
                  className="flex items-center px-3 py-1.5 text-blue-600 hover:text-blue-700 hover:bg-blue-50 rounded-md transition-colors"
                >
                  <Edit2 size={16} className="mr-1.5" />
                  <span>Edit Customer</span>
                </button>
              )}
            </div>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <X size={20} />
            </button>
          </div>
        </div>

        {/* Address Manager */}
        <div className="border-t">
          <div className="p-6">
            <AddressManager customerId={customer.id} />
          </div>
        </div>

        {/* Order List */}
        <div className="flex-1 overflow-auto p-6 border-t">
          <h3 className="text-lg font-semibold mb-4">Order History</h3>
          {editedCustomer.orders && editedCustomer.orders.length > 0 ? (
            <div className="space-y-4">
              {editedCustomer.orders.map((order) => (
                <div
                  key={order.id}
                  onClick={() => handleOrderClick(order)}
                  className="border rounded-lg p-4 hover:bg-gray-50 cursor-pointer transition-colors"
                >
                  <div className="flex justify-between items-start">
                    <div>
                      <div className="font-medium">Order #{order.orderNumber}</div>
                      <div className="text-sm text-gray-500 mt-1">
                        Created {formatDate(order.createdAt)}
                      </div>
                      <div className="mt-2">
                        <OrderStatusBadge status={order.status} />
                      </div>
                    </div>
                    <div className="text-sm text-gray-500">
                      {order.items?.length || 0} items
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center text-gray-500 py-8">
              No orders found for this customer
            </div>
          )}
        </div>

        {/* Footer */}
        {isEditing && (
          <div className="px-6 py-4 border-t border-gray-200 flex-shrink-0 bg-white">
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => {
                  setIsEditing(false);
                  setEditedCustomer(customer);
                }}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <div className="flex items-center gap-4">
                {saveError && (
                  <span className="text-sm text-red-600">{saveError}</span>
                )}
                <button
                  onClick={handleSaveChanges}
                  disabled={isSaving}
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors flex items-center disabled:bg-blue-400"
                >
                  {isSaving ? (
                    <>
                      <span className="mr-2">Saving...</span>
                      <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent" />
                    </>
                  ) : (
                    <>
                      <span className="mr-1.5">Save Changes</span>
                      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
