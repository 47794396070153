import { CustomerType } from './customer';

export enum OrderStatus {
  PLACED = 'PLACED',
  IN_PRODUCTION = 'IN_PRODUCTION',
  PACKED = 'PACKED',
  READY_TO_SHIP = 'READY_TO_SHIP',
  SHIPPED = 'SHIPPED',
  CANCELLED = 'CANCELLED'
}

export enum OrderPriority {
  LOW = 'LOW',
  NORMAL = 'NORMAL',
  HIGH = 'HIGH',
  URGENT = 'URGENT'
}

export enum PickListStatus {
  PLANNED = 'PLANNED',
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED'
}

export interface OrderItem {
  productId: string;
  productTitle: string;
  variantTitle?: string;
  quantity: number;
}

export interface Customer {
  name: string;
  email: string;
  orderCount: number;
  customerType: CustomerType;
}

export interface PickList {
  id: string;
  status: PickListStatus;
}

export interface PickListOrder {
  pickList: PickList;
}

export interface Order {
  id: string;
  orderNumber: string;
  status: OrderStatus;
  priority: OrderPriority;
  customer?: Customer;
  items: OrderItem[];
  isReplacement: boolean;
  tracking: TrackingInfo[];
  notes?: Array<{
    content: string;
    createdAt: string;
    createdBy: string;
  }>;
  verifiedAt?: string;
  verifiedBy?: string;
  createdAt: string;
  pickListOrders?: PickListOrder[];
  deletedAt?: string;
}

export interface TrackingInfo {
  trackingNumber: string | null;
  trackingUrl: string | null;
  carrier: string;
}

export interface OrderDetailsModalProps {
  order: Order | null;
  onClose: () => void;
}

export interface VerifyOrderParams {
  pickListId: string;
  orderId: string;
  verificationNotes: string;
}

export interface OrderFilters {
  status?: string;
  priority?: string;
  search?: string;
  includeDeleted?: boolean;
  isReplacement?: boolean;
}

export enum EventType {
  STATUS_CHANGE = 'STATUS_CHANGE',
  PRIORITY_CHANGE = 'PRIORITY_CHANGE',
  NOTE_ADDED = 'NOTE_ADDED',
  PHOTO_ADDED = 'PHOTO_ADDED',
  SCHEDULED = 'SCHEDULED',
  BATCH_ASSIGNED = 'BATCH_ASSIGNED',
  CUSTOMER_NOTIFIED = 'CUSTOMER_NOTIFIED',
  ORDER_UPDATED = 'ORDER_UPDATED'
}

export interface OrderChange {
  field: string;
  oldValue: any;
  newValue: any;
  description: string;
}

export interface TimelineEvent {
  id: string;
  type: EventType;
  description: string;
  createdAt: string;
  createdBy: string;
  metadata?: {
    previousStatus?: string;
    previousPriority?: string;
    scheduledDate?: string;
    notificationType?: string;
    changes?: OrderChange[];
    itemsRemoved?: boolean;
  };
}
