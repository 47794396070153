import { axiosInstance } from './client';
import { Order, OrderPriority } from '../types/order';

interface OrdersResponse {
  data: Order[];
  total: number;
}

interface OrderItem {
  productId: string;
  productTitle: string;
  variantTitle?: string;
  quantity: number;
}

export const getOrders = async (params: {
  page?: number;
  limit?: number;
  status?: string;
  priority?: string;
  search?: string;
  isReplacement?: boolean;
  includeDeleted?: boolean;
}): Promise<OrdersResponse> => {
  const { data } = await axiosInstance.get('/orders', { params });
  return data;
};

export const getOrderById = async (id: string): Promise<Order> => {
  const response = await axiosInstance.get(`/orders/${id}`);
  return response.data.data;
};

export const updateOrderPriority = async (orderId: string, priority: OrderPriority): Promise<Order> => {
  const response = await axiosInstance.patch(`/orders/${orderId}/priority`, { priority });
  return response.data.data;
};

export const updateOrderItems = async (orderId: string, userId: string, items: OrderItem[]): Promise<Order> => {
  const response = await axiosInstance.patch(`/orders/${orderId}/items`, { userId, items });
  return response.data.data;
};

export const deleteOrder = async (orderId: string): Promise<void> => {
  await axiosInstance.delete(`/orders/${orderId}`);
};

interface BackendProduct {
  title: string;
  productId: string;
  variants: Array<{
    id: string | null;
    title: string;
  }>;
}

export const getUniqueProducts = async (): Promise<{
  status: string;
  data: Array<{
    productId: string;
    productTitle: string;
    variantId: string | null;
    variantTitle: string | null;
  }>;
}> => {
  const response = await axiosInstance.get('/orders/products/unique');
  const products = response.data.data;
  
  // Transform the backend response into the format the frontend expects
  const transformedProducts = products.flatMap((product: BackendProduct) => 
    product.variants.length > 0
      ? product.variants.map((variant: { id: string | null; title: string }) => ({
          productId: product.productId,
          productTitle: product.title,
          variantId: variant.id,
          variantTitle: variant.title
        }))
      : [{
          productId: product.productId,
          productTitle: product.title,
          variantId: null,
          variantTitle: null
        }]
  );

  return {
    status: response.data.status,
    data: transformedProducts
  };
};
