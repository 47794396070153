import { axiosInstance } from './client';
import { Customer, CustomerUpdateInput, Address, AddressInput, CustomerType } from '../types/customer';

interface CreateCustomerInput {
  email: string;
  customerType: CustomerType;
  firstName?: string;
  lastName?: string;
  businessName?: string;
  abn?: string;
  address?: AddressInput;
}

export const createCustomer = async (data: CreateCustomerInput): Promise<Customer> => {
  const { data: response } = await axiosInstance.post('/customers', data);
  return response;
};

export const getCustomers = async (): Promise<Customer[]> => {
  const { data } = await axiosInstance.get('/customers');
  return data;
};

export const getCustomerById = async (id: string): Promise<Customer> => {
  const { data } = await axiosInstance.get(`/customers/${id}`);
  return data;
};

export const updateCustomer = async (
  id: string,
  updateData: CustomerUpdateInput
): Promise<Customer> => {
  const { data } = await axiosInstance.patch(`/customers/${id}`, updateData);
  return data;
};

export const getCustomerAddresses = async (customerId: string): Promise<Address[]> => {
  const { data } = await axiosInstance.get(`/customers/${customerId}/addresses`);
  return data;
};

export const addCustomerAddress = async (
  customerId: string,
  address: AddressInput
): Promise<Address> => {
  const { data } = await axiosInstance.post(`/customers/${customerId}/addresses`, address);
  return data;
};

export const updateCustomerAddress = async (
  customerId: string,
  addressId: string,
  address: Partial<AddressInput>
): Promise<Address> => {
  const { data } = await axiosInstance.patch(
    `/customers/${customerId}/addresses/${addressId}`,
    address
  );
  return data;
};

export const deleteCustomerAddress = async (
  customerId: string,
  addressId: string
): Promise<void> => {
  await axiosInstance.delete(`/customers/${customerId}/addresses/${addressId}`);
};
