// src/components/picklist/PickListOrderSelector.tsx
import { useState, useEffect } from 'react';
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { DateRangePicker } from '@/components/ui/date-range-picker';
import { format } from 'date-fns';
import { Order, OrderPriority } from '@/types/order';
import { PickListOrderSelectorProps } from '@/types/picklist';
import { pickListApi } from '@/api/pickList';
import { toast } from '@/components/ui/use-toast';
import { DateRange } from 'react-day-picker';

// Import @dnd-kit components
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';

import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';

import { CSS } from '@dnd-kit/utilities';

export const PickListOrderSelector = ({
  selectedOrders,
  onOrdersSelected,
}: PickListOrderSelectorProps) => {
  // Filter states
  const [dateRange, setDateRange] = useState<DateRange | null>(null);
  const [priority, setPriority] = useState<OrderPriority | 'ALL'>('ALL');
  const [customerSearch, setCustomerSearch] = useState('');
  const [orderSearch, setOrderSearch] = useState('');
  const [productFilter, setProductFilter] = useState('');

  // Sorting states
  const [availableSortCriteria, setAvailableSortCriteria] = useState<Array<'date' | 'priority'>>(['priority', 'date']);
  const [selectedSortCriteria, setSelectedSortCriteria] = useState<Array<'date' | 'priority'>>(['priority', 'date']);

  const [orders, setOrders] = useState<Order[]>([]);

  // Fetch orders based on filters
  const fetchOrders = async () => {
    try {
      const filters = {
        dateFrom: dateRange?.from?.toISOString(),
        dateTo: dateRange?.to?.toISOString(),
        priority: priority === "ALL" ? undefined : priority,
        customerName: customerSearch,
        orderNumber: orderSearch,
        productTitle: productFilter,
      };

      const response = await pickListApi.getAvailableOrders(filters);
      setOrders(response.data);
    } catch (error) {
      console.error('Error fetching orders:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to fetch orders",
      });
    }
  };

  useEffect(() => {
    fetchOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, priority, customerSearch, orderSearch, productFilter]);

  // Filter orders
  const filteredOrders = orders.filter((order: Order) => {
    const matchesDate =
      (!dateRange?.from || new Date(order.createdAt) >= dateRange.from) &&
      (!dateRange?.to || new Date(order.createdAt) <= dateRange.to);

    const matchesPriority = priority === "ALL" || order.priority === priority;

    const matchesCustomer =
      !customerSearch ||
      order.customer?.name?.toLowerCase().includes(customerSearch.toLowerCase()) || false;

    const matchesOrder =
      !orderSearch ||
      order.orderNumber?.toLowerCase().includes(orderSearch.toLowerCase()) || false;

    const matchesProduct =
      !productFilter ||
      order.items?.some((item) =>
        item.productTitle.toLowerCase().includes(productFilter.toLowerCase())
      );

    return matchesDate && matchesPriority && matchesCustomer && matchesOrder && matchesProduct;
  });

  // Sort orders
  const sortOrders = (ordersToSort: Order[], sortCriteria: Array<'date' | 'priority'>) => {
    return [...ordersToSort].sort((a, b) => {
      for (const criterion of sortCriteria) {
        if (criterion === 'priority') {
          const priorityOrder: { [key in OrderPriority]: number } = {
            'URGENT': 0,
            'HIGH': 1,
            'NORMAL': 2,
            'LOW': 3
          };
          const diff = priorityOrder[a.priority] - priorityOrder[b.priority];
          if (diff !== 0) return diff;
        } else if (criterion === 'date') {
          const dateA = new Date(a.createdAt).getTime();
          const dateB = new Date(b.createdAt).getTime();
          const diff = dateA - dateB;
          if (diff !== 0) return diff;
        }
      }
      return 0;
    });
  };

  const sortedAvailableOrders = sortOrders(filteredOrders, availableSortCriteria);

  // Sensors for DnD Kit
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  // Handle drag and drop
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = selectedOrders.findIndex((order) => order.id === active.id);
      const newIndex = selectedOrders.findIndex((order) => order.id === over?.id);

      const newOrders = arrayMove(selectedOrders, oldIndex, newIndex);
      onOrdersSelected(newOrders);
    }
  };

  // Sortable Item Component
  const SortableItem = ({ order }: { order: Order }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({ id: order.id });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      zIndex: isDragging ? 9999 : 'auto',
    };

    return (
      <div
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        className={`p-4 bg-white border rounded-lg shadow-sm ${
          isDragging ? 'cursor-grabbing' : 'cursor-grab'
        }`}
      >
        <div className="flex justify-between items-center">
          <div>
            <span className="font-medium">#{order.orderNumber}</span>
            <div className="ml-2 inline-flex items-center">
              <span className="text-gray-600">{order.customer?.name || 'No Name'}</span>
              {order.customer?.customerType && (
                <span className={`ml-2 text-xs px-2 py-0.5 rounded-full ${order.customer.customerType === 'BUSINESS' ? 'bg-purple-100 text-purple-800' : 'bg-blue-100 text-blue-800'}`}>
                  {order.customer.customerType === 'BUSINESS' ? 'Business' : 'Individual'}
                </span>
              )}
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <span
              className={`px-2 py-1 rounded-full text-sm ${
                order.priority === 'URGENT'
                  ? 'bg-red-100 text-red-800'
                  : order.priority === 'HIGH'
                  ? 'bg-orange-100 text-orange-800'
                  : order.priority === 'NORMAL'
                  ? 'bg-yellow-100 text-yellow-800'
                  : 'bg-blue-100 text-blue-800'
              }`}
            >
              {order.priority}
            </span>
            <span className="text-sm text-gray-500">
              {format(new Date(order.createdAt), 'MMM dd, yyyy')}
            </span>
          </div>
        </div>
        {/* Order Items */}
        <div className="mt-2 text-sm text-gray-600">
          {(order.items || []).map((item, idx) => (
            <div key={idx}>
              {item.quantity}x {item.productTitle}
              {item.variantTitle && ` (${item.variantTitle})`}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-4">
      {/* Filters */}
      <Card>
        <CardHeader>
          <CardTitle>Filter Orders</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {/* Date Range Picker */}
            <div>
              <label className="text-sm font-medium">Date Range</label>
              <DateRangePicker value={dateRange} onChange={setDateRange} />
            </div>

            {/* Priority Selector */}
            <div>
              <label className="text-sm font-medium">Priority</label>
              <Select value={priority} onValueChange={(value) => setPriority(value as OrderPriority | 'ALL')}>
                <SelectTrigger>
                  <SelectValue placeholder="Select priority" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="ALL">All Priorities</SelectItem>
                  <SelectItem value="URGENT">Urgent</SelectItem>
                  <SelectItem value="HIGH">High</SelectItem>
                  <SelectItem value="NORMAL">Normal</SelectItem>
                  <SelectItem value="LOW">Low</SelectItem>
                </SelectContent>
              </Select>
            </div>

            {/* Customer Search */}
            <div>
              <label className="text-sm font-medium">Customer Name</label>
              <Input
                placeholder="Search customer"
                value={customerSearch}
                onChange={(e) => setCustomerSearch(e.target.value)}
              />
            </div>

            {/* Order Number Search */}
            <div>
              <label className="text-sm font-medium">Order Number</label>
              <Input
                placeholder="Search order number"
                value={orderSearch}
                onChange={(e) => setOrderSearch(e.target.value)}
              />
            </div>

            {/* Product Filter */}
            <div>
              <label className="text-sm font-medium">Product</label>
              <Input
                placeholder="Filter by product"
                value={productFilter}
                onChange={(e) => setProductFilter(e.target.value)}
              />
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Sorting Controls */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Available Orders Sorting */}
        <div className="flex items-center space-x-2">
          <label className="text-sm font-medium">Sort Available Orders:</label>
          <Select
            value={availableSortCriteria.join('-')}
            onValueChange={(value) => {
              const criteria = value.split('-') as Array<'date' | 'priority'>;
              setAvailableSortCriteria(criteria);
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select sort order" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="priority-date">{'Priority > Date'}</SelectItem>
              <SelectItem value="date-priority">{'Date > Priority'}</SelectItem>
              <SelectItem value="priority">{'Priority Only'}</SelectItem>
              <SelectItem value="date">{'Date Only'}</SelectItem>
            </SelectContent>
          </Select>
        </div>

        {/* Selected Orders Sorting */}
        <div className="flex items-center space-x-2">
          <label className="text-sm font-medium">Sort Selected Orders:</label>
          <Select
            value={selectedSortCriteria.join('-')}
            onValueChange={(value) => {
              const criteria = value.split('-') as Array<'date' | 'priority'>;
              setSelectedSortCriteria(criteria);

              // Sort the selectedOrders and update the state
              const sortedOrders = sortOrders(selectedOrders, criteria);
              onOrdersSelected(sortedOrders);
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select sort order" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="priority-date">{'Priority > Date'}</SelectItem>
              <SelectItem value="date-priority">{'Date > Priority'}</SelectItem>
              <SelectItem value="priority">{'Priority Only'}</SelectItem>
              <SelectItem value="date">{'Date Only'}</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Available Orders Section */}
        <Card className="max-h-[600px] overflow-y-auto">
          <CardHeader>
            <CardTitle>Available Orders</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-2">
              {sortedAvailableOrders.map((order: Order) => (
                <div
                  key={order.id}
                  className={`p-4 border rounded-lg shadow-sm transition-all ${
                    selectedOrders.find((o) => o.id === order.id)
                      ? 'bg-blue-50 border-blue-200 cursor-not-allowed'
                      : 'bg-white border-gray-200 hover:shadow-md cursor-pointer'
                  }`}
                  onClick={() => {
                    if (!selectedOrders.find((o) => o.id === order.id)) {
                      onOrdersSelected([...selectedOrders, order]);
                      toast({
                        title: 'Order Selected',
                        description: `Order #${order.orderNumber} added to pick list`,
                      });
                    }
                  }}
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <span className="font-medium">#{order.orderNumber}</span>
                      <div className="ml-2 inline-flex items-center">
                        <span className="text-gray-600">{order.customer?.name || 'No Name'}</span>
                        {order.customer?.customerType && (
                          <span className={`ml-2 text-xs px-2 py-0.5 rounded-full ${order.customer.customerType === 'BUSINESS' ? 'bg-purple-100 text-purple-800' : 'bg-blue-100 text-blue-800'}`}>
                            {order.customer.customerType === 'BUSINESS' ? 'Business' : 'Individual'}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <span
                        className={`px-2 py-1 rounded-full text-sm ${
                          order.priority === 'URGENT'
                            ? 'bg-red-100 text-red-800'
                            : order.priority === 'HIGH'
                            ? 'bg-orange-100 text-orange-800'
                            : order.priority === 'NORMAL'
                            ? 'bg-yellow-100 text-yellow-800'
                            : 'bg-blue-100 text-blue-800'
                        }`}
                      >
                        {order.priority}
                      </span>
                      <span className="text-sm text-gray-500">
                        {format(new Date(order.createdAt), 'dd MMM, yyyy')}
                      </span>
                    </div>
                  </div>
                  {/* Order Items */}
                  <div className="mt-2 text-sm text-gray-600">
                    {(order.items || []).map((item, idx) => (
                      <div key={idx}>
                        {item.quantity}x {item.productTitle}
                        {item.variantTitle && ` (${item.variantTitle})`}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {sortedAvailableOrders.length === 0 && (
                <div className="text-center py-8 text-gray-500">
                  No orders match the current filters
                </div>
              )}
            </div>
          </CardContent>
        </Card>

        {/* Selected Orders (Draggable) */}
        <Card className="max-h-[600px] overflow-y-auto">
          <CardHeader>
            <CardTitle>Selected Orders</CardTitle>
          </CardHeader>
          <CardContent>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={selectedOrders.map((order) => order.id)}
                strategy={verticalListSortingStrategy}
              >
                <div className="space-y-2">
                  {selectedOrders.map((order) => (
                    <SortableItem key={order.id} order={order} />
                  ))}
                </div>
              </SortableContext>
            </DndContext>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
