import React, { useState, useEffect } from 'react';
import { axiosInstance } from '@/api/client';
import { AlertCircle, Plus, Trash2 } from 'lucide-react';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

import { Customer, CustomerType, AddressInput } from '@/types/customer';
import { getCustomers, createCustomer } from '@/api/customers';

interface Product {
  title: string;
  productId: string;
  variants: Array<{
    id: string | null;
    title: string;
  }>;
}

interface CustomerFormData {
  customerType: CustomerType;
  // Individual fields
  firstName?: string;
  lastName?: string;
  // Business fields
  businessName?: string;
  abn?: string;
  // Common fields
  email: string;
  address: AddressInput;
}

const CreateOrderForm = () => {
  const [orderData, setOrderData] = useState({
    items: [{ productId: '', productTitle: '', variantTitle: '', quantity: 1 }],
    isReplacement: false,
    priority: 'NORMAL',
    notes: ''
  });

  const [customerFormData, setCustomerFormData] = useState<CustomerFormData>({
    customerType: CustomerType.INDIVIDUAL,
    email: '',
    firstName: '',
    lastName: '',
    businessName: '',
    abn: '',
    address: {
      street1: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
      isDefault: true
    }
  });

  const [searchTerm, setSearchTerm] = useState('');
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [isNewCustomer, setIsNewCustomer] = useState(false);
  
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [loadingProducts, setLoadingProducts] = useState(true);

  useEffect(() => {
    fetchProducts();
    fetchCustomers();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const filtered = customers.filter(customer => 
        customer.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (customer.firstName?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
        (customer.lastName?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
        (customer.businessName?.toLowerCase() || '').includes(searchTerm.toLowerCase())
      );
      if (filtered.length === 1) {
        handleCustomerSelect(filtered[0]);
      }
    }
  }, [searchTerm]);

  const validateForm = (): string | null => {
    // Validate items
    if (!orderData.items.some(item => item.productTitle)) {
      return 'Please add at least one product';
    }

    // Validate product variants
    const invalidItems = orderData.items.filter(item => {
      const product = products.find(p => p.title === item.productTitle);
      return product && product.variants.length > 0 && !item.variantTitle;
    });

    if (invalidItems.length > 0) {
      return 'Please select variants for all products that require them';
    }

    if (!isNewCustomer && !selectedCustomer) {
      return 'Please select a customer or create a new one';
    }

    if (isNewCustomer) {
      // Common validation
      if (!customerFormData.email) {
        return 'Email is required';
      }

      // Type-specific validation
      if (customerFormData.customerType === CustomerType.INDIVIDUAL) {
        if (!customerFormData.firstName) {
          return 'First name is required';
        }
        if (!customerFormData.lastName) {
          return 'Last name is required';
        }
      } else {
        if (!customerFormData.businessName) {
          return 'Business name is required';
        }
      }

      // Address validation
      const address = customerFormData.address;
      if (!address.street1) {
        return 'Street address is required';
      }
      if (!address.city) {
        return 'City is required';
      }
      if (!address.state) {
        return 'State is required';
      }
      if (!address.postalCode) {
        return 'Postal code is required';
      }
      if (!address.country) {
        return 'Country is required';
      }
    }

    return null;
  };

  const fetchProducts = async () => {
    try {
      setLoadingProducts(true);
      const response = await axiosInstance.get('/orders/products/unique');
      if (response.data?.data) {
        const products = response.data.data;
        console.debug('Fetched products:', products);
        if (Array.isArray(products) && products.length > 0) {
          setProducts(products);
        } else {
          console.error('No products found in response');
          setError('No products available');
        }
      } else {
        console.error('Invalid response format:', response.data);
        setError('Failed to load products: Invalid response format');
      }
    } catch (err: any) {
      console.error('Failed to fetch products:', err);
      setError('Failed to load products: ' + (err.response?.data?.message || err.message));
    } finally {
      setLoadingProducts(false);
    }
  };

  const fetchCustomers = async () => {
    try {
      const data = await getCustomers();
      setCustomers(data);
    } catch (err) {
      console.error('Error fetching customers:', err);
      setError('Failed to load customers');
    }
  };

  const handleCustomerSelect = (customer: Customer): void => {
    setSelectedCustomer(customer);
    setIsNewCustomer(false);
    setCustomerFormData({
      customerType: customer.customerType,
      email: customer.email,
      firstName: customer.firstName,
      lastName: customer.lastName,
      businessName: customer.businessName,
      abn: customer.abn,
      address: customer.addresses?.[0] || {
        street1: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
        isDefault: true
      }
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }

    setLoading(true);
    setSuccess(false);

    try {
      let customerId = selectedCustomer?.id;
      let customerEmail = selectedCustomer?.email;
      let customerName = selectedCustomer?.name || '';

      if (isNewCustomer) {
        // Create new customer first
        const newCustomer = await createCustomer({
          email: customerFormData.email,
          customerType: customerFormData.customerType,
          firstName: customerFormData.firstName,
          lastName: customerFormData.lastName,
          businessName: customerFormData.businessName,
          abn: customerFormData.abn,
          address: customerFormData.address
        });

        customerId = newCustomer.id;
        customerEmail = newCustomer.email;
        customerName = newCustomer.name;
      }

      const orderPayload = {
        ...orderData,
        customerId,
        customerEmail,
        customerName
      };

      const response = await axiosInstance.post('/orders/manual', orderPayload);
      console.debug('Order creation response:', response.data);
      setSuccess(true);
      
      // Reset form
      setOrderData({
        items: [{ productId: '', productTitle: '', variantTitle: '', quantity: 1 }],
        isReplacement: false,
        priority: 'NORMAL',
        notes: ''
      });
      setCustomerFormData({
        customerType: CustomerType.INDIVIDUAL,
        email: '',
        firstName: '',
        lastName: '',
        businessName: '',
        abn: '',
        address: {
          street1: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
          isDefault: true
        }
      });
      setSelectedCustomer(null);
      setIsNewCustomer(false);
      setSearchTerm('');
    } catch (err: any) {
      console.error('Order creation error:', {
        error: err,
        response: err.response?.data,
        status: err.response?.status,
        data: err.config?.data
      });
      setError(err.response?.data?.message || 'Failed to create order');
    } finally {
      setLoading(false);
    }
  };

  const addItem = () => {
    setOrderData(prev => ({
      ...prev,
      items: [...prev.items, { productId: '', productTitle: '', variantTitle: '', quantity: 1 }]
    }));
  };

  const removeItem = (index: number) => {
    setOrderData(prev => ({
      ...prev,
      items: prev.items.filter((_, i) => i !== index)
    }));
  };

  const updateItem = (index: number, field: string, value: string | number) => {
    setOrderData(prev => ({
      ...prev,
      items: prev.items.map((item, i) => {
        if (i === index) {
          const updatedItem = { ...item, [field]: value };
          // When product changes, update productId and clear variant
          if (field === 'productTitle') {
            const selectedProduct = products.find(p => p.title === value);
            updatedItem.productId = selectedProduct?.productId || '';
            updatedItem.variantTitle = '';
          }
          return updatedItem;
        }
        return item;
      })
    }));
  };

  const getVariantsForProduct = (productTitle: string) => {
    const product = products.find(p => p.title === productTitle);
    return product?.variants || [];
  };

  if (loadingProducts) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {success && (
        <Card className="bg-green-50 p-4">
          <p className="text-green-800">Order created successfully!</p>
        </Card>
      )}

      {error && (
        <Card className="bg-red-50 p-4">
          <div className="flex items-center gap-2 text-red-800">
            <AlertCircle className="h-5 w-5" />
            <p>{error}</p>
          </div>
        </Card>
      )}

      {/* Customer Selection/Creation */}
      <div className="space-y-6">
        {selectedCustomer ? (
          <div className="space-y-4">
            <div className="flex justify-between items-start">
              <div>
                <h3 className="text-lg font-medium">Selected Customer</h3>
                <div className="mt-2 space-y-1">
                  <div className="font-medium">
                    {selectedCustomer.customerType === CustomerType.BUSINESS 
                      ? selectedCustomer.businessName
                      : `${selectedCustomer.firstName} ${selectedCustomer.lastName}`}
                  </div>
                  <div className="text-sm text-gray-500">{selectedCustomer.email}</div>
                  {selectedCustomer.addresses?.[0] && (
                    <div className="text-sm text-gray-500 mt-2">
                      <div>{selectedCustomer.addresses[0].street1}</div>
                      <div>
                        {selectedCustomer.addresses[0].city}, {selectedCustomer.addresses[0].state} {selectedCustomer.addresses[0].postalCode}
                      </div>
                      <div>{selectedCustomer.addresses[0].country}</div>
                    </div>
                  )}
                </div>
              </div>
              <Button
                type="button"
                onClick={() => {
                  setSelectedCustomer(null);
                  setSearchTerm('');
                }}
                variant="outline"
                size="sm"
              >
                Change Customer
              </Button>
            </div>
          </div>
        ) : (
          <div className="space-y-4">
            <div className="flex gap-4 items-center">
              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Search Existing Customer
                </label>
                <input
                  type="text"
                  className="w-full rounded-md border border-gray-300 px-3 py-2"
                  placeholder="Search by email or name..."
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setIsNewCustomer(false);
                  }}
                />
              </div>
              <div className="pt-6">
                <Button
                  type="button"
                  onClick={() => {
                    setIsNewCustomer(true);
                    setSearchTerm('');
                    setSelectedCustomer(null);
                    setSearchTerm('');
                  }}
                  variant="outline"
                >
                  Add New Customer
                </Button>
              </div>
            </div>

            {searchTerm && !isNewCustomer && (
              <div className="border rounded-md p-4">
                {customers
                  .filter(customer => 
                    customer.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (customer.firstName?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
                    (customer.lastName?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
                    (customer.businessName?.toLowerCase() || '').includes(searchTerm.toLowerCase())
                  )
                  .slice(0, 5)
                  .map(customer => (
                    <div
                      key={customer.id}
                      className="p-2 hover:bg-gray-50 cursor-pointer rounded-md"
                      onClick={() => handleCustomerSelect(customer)}
                    >
                      <div className="font-medium">
                        {customer.customerType === CustomerType.BUSINESS 
                          ? customer.businessName
                          : `${customer.firstName} ${customer.lastName}`}
                      </div>
                      <div className="text-sm text-gray-500">{customer.email}</div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        )}

        {isNewCustomer && !selectedCustomer && (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Customer Type
              </label>
              <div className="flex gap-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    className="form-radio"
                    checked={customerFormData.customerType === CustomerType.INDIVIDUAL}
                    onChange={() => setCustomerFormData(prev => ({
                      ...prev,
                      customerType: CustomerType.INDIVIDUAL,
                      businessName: '',
                      abn: ''
                    }))}
                  />
                  <span className="ml-2">Individual</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    className="form-radio"
                    checked={customerFormData.customerType === CustomerType.BUSINESS}
                    onChange={() => setCustomerFormData(prev => ({
                      ...prev,
                      customerType: CustomerType.BUSINESS,
                      firstName: '',
                      lastName: ''
                    }))}
                  />
                  <span className="ml-2">Business</span>
                </label>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  required
                  className="w-full rounded-md border border-gray-300 px-3 py-2"
                  value={customerFormData.email}
                  onChange={(e) => setCustomerFormData(prev => ({
                    ...prev,
                    email: e.target.value
                  }))}
                />
              </div>

              {customerFormData.customerType === CustomerType.INDIVIDUAL ? (
                <>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      First Name
                    </label>
                    <input
                      type="text"
                      required
                      className="w-full rounded-md border border-gray-300 px-3 py-2"
                      value={customerFormData.firstName}
                      onChange={(e) => setCustomerFormData(prev => ({
                        ...prev,
                        firstName: e.target.value
                      }))}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Last Name
                    </label>
                    <input
                      type="text"
                      required
                      className="w-full rounded-md border border-gray-300 px-3 py-2"
                      value={customerFormData.lastName}
                      onChange={(e) => setCustomerFormData(prev => ({
                        ...prev,
                        lastName: e.target.value
                      }))}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Business Name
                    </label>
                    <input
                      type="text"
                      required
                      className="w-full rounded-md border border-gray-300 px-3 py-2"
                      value={customerFormData.businessName}
                      onChange={(e) => setCustomerFormData(prev => ({
                        ...prev,
                        businessName: e.target.value
                      }))}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      ABN
                    </label>
                    <input
                      type="text"
                      className="w-full rounded-md border border-gray-300 px-3 py-2"
                      value={customerFormData.abn}
                      onChange={(e) => setCustomerFormData(prev => ({
                        ...prev,
                        abn: e.target.value
                      }))}
                    />
                  </div>
                </>
              )}
            </div>

            {/* Address Fields */}
            <div className="space-y-4">
              <h3 className="text-lg font-medium">Address</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="md:col-span-2">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Street Address
                  </label>
                  <input
                    type="text"
                    required
                    className="w-full rounded-md border border-gray-300 px-3 py-2"
                    value={customerFormData.address.street1}
                    onChange={(e) => setCustomerFormData(prev => ({
                      ...prev,
                      address: { ...prev.address, street1: e.target.value }
                    }))}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    City
                  </label>
                  <input
                    type="text"
                    required
                    className="w-full rounded-md border border-gray-300 px-3 py-2"
                    value={customerFormData.address.city}
                    onChange={(e) => setCustomerFormData(prev => ({
                      ...prev,
                      address: { ...prev.address, city: e.target.value }
                    }))}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    State
                  </label>
                  <input
                    type="text"
                    required
                    className="w-full rounded-md border border-gray-300 px-3 py-2"
                    value={customerFormData.address.state}
                    onChange={(e) => setCustomerFormData(prev => ({
                      ...prev,
                      address: { ...prev.address, state: e.target.value }
                    }))}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Postal Code
                  </label>
                  <input
                    type="text"
                    required
                    className="w-full rounded-md border border-gray-300 px-3 py-2"
                    value={customerFormData.address.postalCode}
                    onChange={(e) => setCustomerFormData(prev => ({
                      ...prev,
                      address: { ...prev.address, postalCode: e.target.value }
                    }))}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Country
                  </label>
                  <input
                    type="text"
                    required
                    className="w-full rounded-md border border-gray-300 px-3 py-2"
                    value={customerFormData.address.country}
                    onChange={(e) => setCustomerFormData(prev => ({
                      ...prev,
                      address: { ...prev.address, country: e.target.value }
                    }))}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Order Items */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Order Items
        </label>
        <div className="space-y-3">
          {orderData.items.map((item, index) => (
            <div key={index} className="flex gap-3">
              <Select
                value={item.productTitle}
                onValueChange={(value: string) => updateItem(index, 'productTitle', value)}
              >
                <SelectTrigger className="flex-1">
                  <SelectValue placeholder="Select product" />
                </SelectTrigger>
                <SelectContent>
                  {products.map((product) => (
                    <SelectItem key={product.title} value={product.title}>
                      {product.title}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>

              {getVariantsForProduct(item.productTitle).length > 0 && (
                <Select
                  value={item.variantTitle}
                  onValueChange={(value: string) => updateItem(index, 'variantTitle', value)}
                >
                  <SelectTrigger className="w-48">
                    <SelectValue placeholder="Select variant" />
                  </SelectTrigger>
                  <SelectContent>
                    {getVariantsForProduct(item.productTitle).map((variant) => (
                      <SelectItem key={variant.id} value={variant.title}>
                        {variant.title}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}

              <input
                type="number"
                min="1"
                required
                className="w-24 rounded-md border border-gray-300 px-3 py-2"
                value={item.quantity}
                onChange={(e) => updateItem(index, 'quantity', parseInt(e.target.value))}
              />

              {orderData.items.length > 1 && (
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  onClick={() => removeItem(index)}
                  className="text-red-600 hover:text-red-800 hover:bg-red-50"
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
              )}
            </div>
          ))}
        </div>
        <Button
          type="button"
          variant="ghost"
          onClick={addItem}
          className="mt-2 text-blue-600 hover:text-blue-800 flex items-center gap-1"
        >
          <Plus className="h-4 w-4" />
          Add Another Item
        </Button>
      </div>

      {/* Order Options */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Order Type
          </label>
          <div>
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className="rounded border-gray-300 text-blue-600"
                checked={orderData.isReplacement}
                onChange={(e) => setOrderData(prev => ({
                  ...prev,
                  isReplacement: e.target.checked
                }))}
              />
              <span className="ml-2 text-sm text-gray-600">Replacement Order</span>
            </label>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Priority
          </label>
          <Select
            value={orderData.priority}
            onValueChange={(value: string) => setOrderData(prev => ({ ...prev, priority: value }))}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select priority" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="LOW">Low</SelectItem>
              <SelectItem value="NORMAL">Normal</SelectItem>
              <SelectItem value="HIGH">High</SelectItem>
              <SelectItem value="URGENT">Urgent</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      {/* Notes */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Notes
        </label>
        <textarea
          className="w-full rounded-md border border-gray-300 px-3 py-2"
          rows={3}
          value={orderData.notes}
          onChange={(e) => setOrderData(prev => ({
            ...prev,
            notes: e.target.value
          }))}
        />
      </div>

      {/* Submit Button */}
      <div className="flex justify-end">
        <Button
          type="submit"
          disabled={loading}
          className="bg-blue-600 text-white hover:bg-blue-700"
        >
          {loading ? 'Creating Order...' : 'Create Order'}
        </Button>
      </div>
    </form>
  );
};

export default CreateOrderForm;
