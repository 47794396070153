import React from 'react';
import { X, Calendar, Package, User, Mail, Clock, AlertCircle, Truck, History } from 'lucide-react';
import { Order, OrderDetailsModalProps } from '../types/order';
import OrderTimeline from './OrderTimeline';

const OrderDetailsModal: React.FC<OrderDetailsModalProps> = ({ order, onClose }) => {
  if (!order) return null;

  const formatDate = (dateString: string) => {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(new Date(dateString));
  };

  const getPriorityColor = (priority: Order['priority']) => {
    switch (priority) {
      case 'URGENT':
        return 'bg-red-100 text-red-800';
      case 'HIGH':
        return 'bg-orange-100 text-orange-800';
      case 'NORMAL':
        return 'bg-blue-100 text-blue-800';
      case 'LOW':
        return 'bg-gray-100 text-gray-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-2 sm:p-4">
      <div className="bg-white rounded-lg w-full max-w-2xl max-h-[95vh] sm:max-h-[90vh] overflow-hidden">
        {/* Header */}
        <div className="px-4 sm:px-6 py-3 sm:py-4 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2">
            <div className="flex items-center space-x-3">
              <h2 className="text-lg sm:text-xl font-semibold">Order #{order.orderNumber}</h2>
              <span className={`text-sm px-2 py-1 rounded-full ${getPriorityColor(order.priority)}`}>
                {order.priority}
              </span>
            </div>
            <button
              onClick={onClose}
              className="absolute top-2 right-2 sm:static text-gray-500 hover:text-gray-700 transition-colors p-2"
            >
              <X size={20} />
            </button>
          </div>
        </div>

        {/* Content */}
        <div className="p-4 sm:p-6 overflow-y-auto max-h-[calc(95vh-8rem)] sm:max-h-[calc(90vh-8rem)]">
          {/* Order Details */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6">
            <div className="space-y-4">
              <h3 className="font-medium text-gray-900 text-base sm:text-lg">Order Information</h3>
              <div className="space-y-3">
                <div className="flex items-center text-sm sm:text-base text-gray-600">
                  <Calendar size={16} className="mr-2 flex-shrink-0" />
                  <span className="break-all">Created: {formatDate(order.createdAt)}</span>
                </div>
                <div className="flex items-center text-sm sm:text-base text-gray-600">
                  <Clock size={16} className="mr-2 flex-shrink-0" />
                  <span>Status: {order.status.replace(/_/g, ' ')}</span>
                </div>
                {order.isReplacement && (
                  <div className="flex items-center text-sm sm:text-base text-gray-600">
                    <AlertCircle size={16} className="mr-2 flex-shrink-0" />
                    <span>Replacement Order</span>
                  </div>
                )}
              </div>
            </div>

            <div className="space-y-4">
              <h3 className="font-medium text-gray-900 text-base sm:text-lg">Customer Details</h3>
              <div className="space-y-3">
                <div className="flex items-center text-sm sm:text-base text-gray-600">
                  <User size={16} className="mr-2 flex-shrink-0" />
                  <div className="flex flex-col">
                    <span className="break-all">{order.customer?.name || 'No Name'}</span>
                    {order.customer?.customerType && (
                      <span className={`text-xs px-2 py-0.5 rounded-full mt-1 inline-block w-fit ${order.customer.customerType === 'BUSINESS' ? 'bg-purple-100 text-purple-800' : 'bg-blue-100 text-blue-800'}`}>
                        {order.customer.customerType === 'BUSINESS' ? 'Business' : 'Individual'}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex items-center text-sm sm:text-base text-gray-600">
                  <Mail size={16} className="mr-2 flex-shrink-0" />
                  <span className="break-all">{order.customer?.email || 'No Email'}</span>
                </div>
              </div>
            </div>
          </div>

          {/* Order Items */}
          <div className="mb-6">
            <h3 className="font-medium text-gray-900 text-base sm:text-lg mb-4">Order Items</h3>
            <div className="bg-gray-50 rounded-lg">
              {order.items && order.items.length > 0 ? (
                <div className="divide-y divide-gray-200">
                  {order.items.map((item, index) => (
                    <div key={index} className="p-3 sm:p-4">
                      <div className="flex items-start sm:items-center justify-between gap-2">
                        <div className="flex items-start sm:items-center">
                          <Package size={16} className="mr-2 flex-shrink-0 mt-1 sm:mt-0 text-gray-500" />
                          <div className="flex flex-col sm:flex-row sm:items-center gap-1">
                            <span className="text-sm font-medium">{item.productTitle}</span>
                            {item.variantTitle && (
                              <span className="text-sm text-gray-500 sm:ml-1">
                                ({item.variantTitle})
                              </span>
                            )}
                          </div>
                        </div>
                        <span className="text-sm font-medium whitespace-nowrap">Qty: {item.quantity}</span>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="p-4 text-sm text-gray-500 text-center">
                  No items found
                </div>
              )}
            </div>
          </div>

          {/* Tracking Information */}
          {order.tracking && order.tracking.length > 0 && (
            <div className="mb-6">
              <h3 className="font-medium text-gray-900 text-base sm:text-lg mb-4">Shipping Information</h3>
              <div className="space-y-3">
                {order.tracking.map((info, index) => (
                  <div key={index} className="bg-gray-50 p-3 sm:p-4 rounded-lg">
                    <div className="flex items-center space-x-2 mb-2">
                      <Truck size={16} className="text-gray-500 flex-shrink-0" />
                      <span className="font-medium">{info.carrier}</span>
                    </div>
                    {info.trackingNumber && (
                      <div className="text-sm break-all">
                        Tracking Number: {
                          info.trackingUrl ? (
                            <a 
                              href={info.trackingUrl} 
                              target="_blank" 
                              rel="noopener noreferrer"
                              className="text-blue-600 hover:text-blue-800"
                            >
                              {info.trackingNumber}
                            </a>
                          ) : (
                            info.trackingNumber
                          )
                        }
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Timeline */}
          <div className="mb-6">
            <div className="flex items-center gap-2 mb-4">
              <History size={18} className="text-gray-700" />
              <h3 className="font-medium text-gray-900 text-base sm:text-lg">Order Timeline</h3>
            </div>
            <OrderTimeline orderId={order.id} />
          </div>

          {/* Order Notes */}
          {order.notes && order.notes.length > 0 && (
            <div>
              <h3 className="font-medium text-gray-900 text-base sm:text-lg mb-4">Notes</h3>
              <div className="space-y-3">
                {order.notes.map((note, index) => (
                  <div key={index} className="bg-gray-50 p-3 sm:p-4 rounded-lg">
                    <p className="text-sm sm:text-base text-gray-600 mb-2 break-words">{note.content}</p>
                    <div className="text-xs text-gray-500">
                      By {note.createdBy} • {formatDate(note.createdAt)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="px-4 sm:px-6 py-3 sm:py-4 border-t border-gray-200 flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsModal;
