// packages/frontend/src/App.tsx
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavigationLayout from './components/NavigationLayout';
import ProtectedRoute from './components/ProtectedRoute';
import { useAuthStore } from './stores/authStore';
import { Toaster } from './components/ui/toaster';

// Import your pages
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import OrderHistoryPage from './pages/OrderHistoryPage';
import CreateOrderPage from './pages/CreateOrderPage';
import ProductionPage from './pages/ProductionPage';
import SettingsPage from './pages/SettingsPage';
import AdminPickListPage from './pages/AdminPickListPage';
import OperationsPickListPage from './pages/OperationsPickListPage';
import VerifyOrderPage from './pages/VerifyOrderPage';
import PlaceholderPage from './components/PlaceholderPage';
import CustomersPage from './pages/CustomersPage';

const App = () => {

  const { checkAuth } = useAuthStore();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  return (
    <Router>
      <Routes>
        {/* Public routes */}
        <Route path="/login" element={<LoginPage />} />
        
        {/* Protected routes wrapper */}
        <Route element={<ProtectedRoute />}>
          {/* Navigation layout wrapper */}
          <Route element={<NavigationLayout />}>
            {/* Dashboard */}
            <Route path="/" element={<DashboardPage />} />
            
            {/* Order routes */}
            <Route path="/orders" element={<OrderHistoryPage />} />
            <Route path="/orders/create" element={<CreateOrderPage />} />
            
            {/* Production route */}
            <Route path="/production" element={<ProductionPage />} />
            
            {/* Pick List routes */}
            <Route path="/pick-lists/manage" element={<AdminPickListPage />} />
            <Route path="/pick-lists/active" element={<OperationsPickListPage />} />
            <Route path="/verify-order/:orderId" element={<VerifyOrderPage />} />

            {/* Placeholder routes */}
            <Route 
              path="/inventory" 
              element={
                <PlaceholderPage
                  title="Inventory Management"
                  description="This feature is coming soon. It will help you manage your product inventory effectively."
                />
              }
            />
            
            <Route 
              path="/analytics" 
              element={
                <PlaceholderPage
                  title="Analytics Dashboard"
                  description="This feature is under development. It will provide insights into your business performance."
                />
              }
            />
            
            <Route path="/customers" element={<CustomersPage />} />

            {/* Settings */}
            <Route path="/settings" element={<SettingsPage />} />
          </Route>
        </Route>
      </Routes>

      <Toaster />

    </Router>
  );
};

export default App;
