// src/api/pickList.ts
import { axiosInstance } from './client';
import { OrderFilters } from '../types/picklist';
import { VerifyOrderParams } from '../types/order';

export const pickListApi = {
  // Admin functions
  getPlannedAndActivePickLists: async () => {
    try {
      const response = await axiosInstance.get('/pick-lists/planned-and-active');
      return response.data;
    } catch (error) {
      console.error('Error fetching planned and active pick lists:', error);
      throw error;
    }
  },

  getAvailableOrders: async (filters?: OrderFilters) => {
    try {
      // Remove undefined values and create query params
      const queryParams = new URLSearchParams();
      if (filters) {
        Object.entries(filters).forEach(([key, value]) => {
          if (value !== undefined && value !== null) {
            queryParams.append(key, String(value));
          }
        });
      }

      const queryString = queryParams.toString();
      const url = `/pick-lists/available-orders${queryString ? `?${queryString}` : ''}`;
      
      console.log('Fetching available orders:', url);
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      console.error('Error fetching available orders:', error);
      throw error;
    }
  },

  createPickList: async (params: { orderIds: string[], plannedDate: string }) => {
    try {
      const response = await axiosInstance.post('/pick-lists', params);
      return response.data;
    } catch (error) {
      console.error('Error creating pick list:', error);
      throw error;
    }
  },

  cancelPickList: async (pickListId: string) => {
    console.log('Cancelling pick list:', pickListId);
    try {
      const response = await axiosInstance.post(`/pick-lists/${pickListId}/cancel`);
      console.log('Pick list cancelled successfully:', response.data);
      return response.data;
    } catch (error: any) {
      console.error('Error cancelling pick list:', error);
      throw error;
    }
  },

  // Operations functions
  getActivePickList: async () => {
    console.log('Fetching active pick list');
    try {
      const response = await axiosInstance.get('/pick-lists/active');
      console.log('Active pick list fetched:', response.data);
      return response.data;
    } catch (error: any) {
      console.error('Error fetching active pick list:', error);
      throw error;
    }
  },

  printLabels: async (pickListId: string) => {
    console.log('Calling print labels endpoint for:', pickListId);
    const response = await axiosInstance.post(
      `/pick-lists/${pickListId}/print-labels`,
      {},
      { responseType: 'blob' }
    );
    console.log('Labels response:', response);
    return response.data;
  },

  printPackingSlips: async (pickListId: string) => {
    console.log('Printing packing slips for pick list:', pickListId);
    try {
      const response = await axiosInstance.post(
        `/pick-lists/${pickListId}/print-slips`,
        {},
        { responseType: 'blob' }
      );
      console.log('Packing slips generated successfully');
      return response.data;
    } catch (error: any) {
      console.error('Error printing packing slips:', error);
      throw error;
    }
  },

  verifyOrder: async ({ pickListId, orderId, verificationNotes }: VerifyOrderParams) => {
    console.log('Verifying order:', { pickListId, orderId, verificationNotes });
    try {
      const response = await axiosInstance.post(
        `/pick-lists/${pickListId}/orders/${orderId}/verify`,
        { verificationNotes }
      );
      console.log('Order verified successfully:', response.data);
      return response.data;
    } catch (error: any) {
      console.error('Error verifying order:', error);
      throw error;
    }
  },

  getPickListAudit: async (pickListId: string) => {
    console.log('Fetching audit trail for pick list:', pickListId);
    try {
      const response = await axiosInstance.get(`/pick-lists/${pickListId}/audit`);
      console.log('Audit trail fetched successfully');
      return response.data;
    } catch (error: any) {
      console.error('Error fetching audit trail:', error);
      throw error;
    }
  },

  // Helper function to download PDF files
  downloadPdf: (blob: Blob, filename: string) => {
    try {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      console.log('PDF downloaded successfully:', filename);
    } catch (error) {
      console.error('Error downloading PDF:', error);
      throw error;
    }
  }
};
