import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getOrders, getOrderById } from '../api/orders';
import EditableOrderDetailsModal from '../components/EditableOrderDetailsModal';
import { OrderFilters, Order, OrderStatus } from '../types/order';
import { Filter } from 'lucide-react';

// Reuse components from OrderBoard
const SearchBar = React.memo(({ onSearch, initialSearchTerm = '' }: { 
  onSearch: (term: string | undefined) => void;
  initialSearchTerm?: string;
}) => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

  useEffect(() => {
    setSearchTerm(initialSearchTerm);
  }, [initialSearchTerm]);
  
  const handleSearch = () => {
    onSearch(searchTerm || undefined);
  };

  const handleReset = () => {
    setSearchTerm('');
    onSearch(undefined);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="flex gap-2">
      <input
        type="text"
        placeholder="Search orders..."
        className="px-3 py-2 border rounded-md w-full max-w-md"
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyPress={handleKeyPress}
        value={searchTerm}
      />
      <button
        onClick={handleSearch}
        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
      >
        Search
      </button>
      {searchTerm && (
        <button
          onClick={handleReset}
          className="px-4 py-2 bg-gray-100 text-gray-600 rounded-md hover:bg-gray-200 transition-colors"
        >
          Reset
        </button>
      )}
    </div>
  );
});

const FilterPanel = React.memo(({ onFilterChange, currentFilters }: {
  onFilterChange: (filterType: string, value: string | boolean) => void;
  currentFilters: OrderFilters;
}) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow mb-4">
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex items-center gap-4">
          <select
            className="px-3 py-2 border rounded-md w-full sm:w-auto"
            onChange={(e) => onFilterChange('priority', e.target.value)}
            value={currentFilters.priority || ''}
          >
            <option value="">All Priorities</option>
            <option value="URGENT">Urgent</option>
            <option value="HIGH">High</option>
            <option value="NORMAL">Normal</option>
            <option value="LOW">Low</option>
          </select>
          <select
            className="px-3 py-2 border rounded-md w-full sm:w-auto"
            onChange={(e) => onFilterChange('status', e.target.value)}
            value={currentFilters.status || ''}
          >
            <option value="">All Statuses</option>
            <option value="PLACED">Placed</option>
            <option value="IN_PRODUCTION">In Production</option>
            <option value="READY_TO_SHIP">Ready to Ship</option>
            <option value="SHIPPED">Shipped</option>
            <option value="PACKED">Packed</option>
            <option value="CANCELLED">Cancelled</option>
          </select>
          <div className="flex items-center gap-4">
            <label className="flex items-center gap-2 text-sm text-gray-600">
              <input
                type="checkbox"
                className="rounded border-gray-300"
                checked={currentFilters.includeDeleted || false}
                onChange={(e) => onFilterChange('includeDeleted', e.target.checked)}
              />
              Show Deleted Orders
            </label>
            <label className="flex items-center gap-2 text-sm text-gray-600">
              <input
                type="checkbox"
                className="rounded border-gray-300"
                checked={currentFilters.isReplacement || false}
                onChange={(e) => onFilterChange('isReplacement', e.target.checked)}
              />
              Replacement Orders
            </label>
          </div>
        </div>
      </div>
    </div>
  );
});

const ORDERS_PER_PAGE = 100;

function OrderHistoryPage() {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const filtersRef = useRef<OrderFilters>({});

  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');

  const fetchOrders = async (pageNum = 1, append = false) => {
    try {
      setLoading(true);

      if (orderId) {
        try {
          // If orderId is present, fetch only that specific order
          const order = await getOrderById(orderId);
          if (order) {
            setOrders([order]);
            setHasMore(false);
            setSelectedOrder(order);
            setTotalOrdersCount(1);
          } else {
            setError('Order not found');
            setOrders([]);
          }
        } catch (err) {
          console.error('Error fetching order:', err);
          setError('Failed to load order');
          setOrders([]);
        }
      } else {
        // Otherwise, fetch orders with filters
        const params: Record<string, string> = {
          page: pageNum.toString(),
          limit: ORDERS_PER_PAGE.toString()
        };

        if (filtersRef.current.status) params.status = filtersRef.current.status;
        if (filtersRef.current.priority) params.priority = filtersRef.current.priority;
        if (filtersRef.current.search) params.search = filtersRef.current.search;
        if (filtersRef.current.includeDeleted) params.includeDeleted = 'true';
        if (typeof filtersRef.current.isReplacement === 'boolean') params.isReplacement = filtersRef.current.isReplacement.toString();

        const response = await getOrders(params);
        const newOrders = response.data;

        if (append) {
          setOrders(prev => [...prev, ...newOrders]);
        } else {
          setOrders(newOrders);
          setPage(1);
        }

        setHasMore(newOrders.length === ORDERS_PER_PAGE);
      }
      setError('');
    } catch (err) {
      setError('Failed to load orders');
      console.error('Error fetching orders:', err);
    } finally {
      setLoading(false);
    }
  };

  const fetchTotalCount = async () => {
    try {
      const response = await getOrders({
        ...filtersRef.current,
        page: 1,
        limit: 1
      });
      setTotalOrdersCount(response.total);
    } catch (err) {
      console.error('Error fetching total count:', err);
    }
  };

  useEffect(() => {
    fetchOrders(1, false);
    if (!orderId) {
      fetchTotalCount();
    }
  }, [orderId]);

  const handleFilterChange = (filterType: string, value: string | boolean) => {
    filtersRef.current = {
      ...filtersRef.current,
      [filterType]: typeof value === 'string' ? (value || undefined) : value
    };
    fetchOrders(1, false);
    fetchTotalCount();
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight * 1.5 && !loading && hasMore) {
      setPage(prev => prev + 1);
      fetchOrders(page + 1, true);
    }
  };

  const getPriorityColor = (priority: Order['priority']) => {
    switch (priority) {
      case 'URGENT':
        return 'bg-red-100 text-red-800';
      case 'HIGH':
        return 'bg-orange-100 text-orange-800';
      case 'NORMAL':
        return 'bg-blue-100 text-blue-800';
      case 'LOW':
        return 'bg-gray-100 text-gray-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusColor = (status: Order['status']) => {
    switch (status) {
      case OrderStatus.PLACED:
        return 'bg-yellow-100 text-yellow-800';
      case OrderStatus.IN_PRODUCTION:
        return 'bg-blue-100 text-blue-800';
      case OrderStatus.PACKED:
        return 'bg-orange-100 text-orange-800';
      case OrderStatus.READY_TO_SHIP:
        return 'bg-green-100 text-green-800';
      case OrderStatus.SHIPPED:
        return 'bg-purple-100 text-purple-800';
      case OrderStatus.CANCELLED:
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (error && orders.length === 0) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-lg text-red-500">{error}</div>
      </div>
    );
  }

  return (
    <div className="p-4 h-full">
      <div className="space-y-6 mb-6">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
          <div className="flex flex-col sm:flex-row gap-4 w-full sm:w-auto">
            <SearchBar 
              initialSearchTerm={filtersRef.current.search || ''}
              onSearch={(term) => handleFilterChange('search', term || '')}
            />
            <button
              onClick={() => setIsFilterOpen(!isFilterOpen)}
              className="flex items-center justify-center gap-2 px-4 py-2 bg-white rounded-lg shadow whitespace-nowrap"
            >
              <Filter size={20} />
              Filters
            </button>
          </div>
          <div className="text-sm text-gray-600 whitespace-nowrap">
            Showing {orders.length} of {totalOrdersCount} orders
          </div>
        </div>

        {isFilterOpen && (
          <FilterPanel 
            onFilterChange={handleFilterChange}
            currentFilters={filtersRef.current}
          />
        )}
      </div>

      <div 
        className="overflow-auto rounded-lg border border-gray-200"
        style={{ height: 'calc(100vh - 220px)' }}
        onScroll={handleScroll}
      >
        <table className="min-w-full bg-white">
          <thead className="bg-gray-50 sticky top-0">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order #</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Priority</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Items</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {orders.map(order => (
              <tr 
                key={order.id}
                onClick={() => setSelectedOrder(order)}
                className={`hover:bg-gray-50 cursor-pointer ${order.deletedAt ? 'bg-gray-50' : ''}`}
              >
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  #{order.orderNumber}
                  {order.deletedAt && (
                    <span className="ml-2 text-xs text-red-600">(Deleted)</span>
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{order.customer?.name || 'No Name'}</div>
                  <div className="text-sm text-gray-500">{order.customer?.email || 'No Email'}</div>
                  {order.customer?.customerType === 'BUSINESS' && (
                    <span className="text-xs px-2 py-0.5 bg-purple-100 text-purple-800 rounded-full mt-1 inline-block">
                      Business
                    </span>
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 py-1 text-xs rounded-full ${getStatusColor(order.status)}`}>
                    {order.status}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 py-1 text-xs rounded-full ${getPriorityColor(order.priority)}`}>
                    {order.priority}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {order.items?.length || 0} items
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {formatDate(order.createdAt)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {loading && (
          <div className="flex justify-center p-4">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
          </div>
        )}
      </div>

      {selectedOrder && (
        <EditableOrderDetailsModal
          order={selectedOrder}
          onClose={() => setSelectedOrder(null)}
          onOrderUpdate={() => {
            fetchOrders(page, false);
            fetchTotalCount();
          }}
        />
      )}
    </div>
  );
}

export default OrderHistoryPage;
