// packages/frontend/src/api/client.ts
import axios from 'axios';

// Use environment variable for API URL
export const API_URL = import.meta.env.VITE_API_URL;

// Create axios instance with minimal configuration
export const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add auth token to requests if it exists
axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Handle API responses and errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle 401 responses
    if (error.response?.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/login';
      return Promise.reject(new Error('Authentication required'));
    }

    // Pass through the original error with the API response
    return Promise.reject(error);
  }
);
