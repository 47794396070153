import { Order } from './order';

export enum CustomerType {
  INDIVIDUAL = 'INDIVIDUAL',
  BUSINESS = 'BUSINESS'
}

export interface Address {
  id: string;
  street1: string;
  street2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  isDefault: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface Customer {
  id: string;
  shopifyId?: string;
  email: string;
  name: string; // Kept for backward compatibility
  customerType: CustomerType;
  // Individual fields
  firstName?: string;
  lastName?: string;
  // Business fields
  businessName?: string;
  abn?: string;
  // Common fields
  isVIP: boolean;
  createdAt: string;
  updatedAt: string;
  orderCount: number;
  activeOrderCount?: number;
  orders?: Order[];
  addresses?: Address[];
}

export interface CustomerUpdateInput {
  email?: string;
  customerType?: CustomerType;
  firstName?: string;
  lastName?: string;
  businessName?: string;
  abn?: string;
  isVIP?: boolean;
}

export interface AddressInput {
  street1: string;
  street2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  isDefault?: boolean;
}
