import { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { useToast } from "@/components/ui/use-toast"
import { List, Plus, Printer, Loader2 } from 'lucide-react';
import { format } from 'date-fns';
import { pickListApi } from '../../api/pickList';
import PickListOrdersModal from './PickListOrdersModal';
import { PickListOrderSelector } from './PickListOrderSelector';
import { Order } from '@/types/order';
import { PickList, PickListStatus } from '@/types/picklist';

const AdminPickListManager = () => {
  const { toast } = useToast();
  const [pickLists, setPickLists] = useState<PickList[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [plannedDate, setPlannedDate] = useState<string>(''); // Changed to string type
  const [selectedPickList, setSelectedPickList] = useState<PickList | null>(null);
  const [isOrdersModalOpen, setIsOrdersModalOpen] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState<Order[]>([]);

  const handleViewOrders = (pickList: PickList) => {
    setSelectedPickList(pickList);
    setIsOrdersModalOpen(true);
  };

  const fetchPickLists = async () => {
    try {
      setIsLoading(true);
      const response = await pickListApi.getPlannedAndActivePickLists();
      
      if (response?.data) {
        // Sort by sequence number
        const sortedPickLists = response.data.sort((a: PickList, b: PickList) => 
          a.sequence - b.sequence
        );
        
        setPickLists(sortedPickLists);
      } else {
        setPickLists([]);
      }
    } catch (error) {
      console.error('Error fetching pick lists:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to fetch pick lists"
      });
      setPickLists([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log('Component mounted, auth state:', {
      hasToken: !!token,
      tokenPreview: token ? `${token.substring(0, 20)}...` : 'no token'
    });

    if (token) {
      fetchPickLists();
    } else {
      console.error('No token found, cannot fetch pick lists');
    }
  }, []);

  const handleCreatePickList = async () => {
    try {
      if (selectedOrders.length === 0) {
        toast({
          variant: "destructive",
          title: "Error",
          description: "Please select orders for the pick list"
        });
        return;
      }

      if (!plannedDate) {
        toast({
          variant: "destructive",
          title: "Error",
          description: "Please select a planned date"
        });
        return;
      }

      setIsCreating(true);

      await pickListApi.createPickList({
        orderIds: selectedOrders.map(order => order.id),
        plannedDate: new Date(plannedDate).toISOString() // Pass the planned date as a string
      });

      // Refresh the pick lists after creation
      await fetchPickLists();

      toast({
        title: "Success",
        description: "Pick list created successfully"
      });

      setSelectedOrders([]);
      setPlannedDate(''); // Reset the planned date

    } catch (error) {
      console.error('Error creating pick list:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to create pick list"
      });
    } finally {
      setIsCreating(false);
    }
  };

  const handleCancelPickList = async (pickListId: string) => {
    try {
      await pickListApi.cancelPickList(pickListId);

      toast({
        title: "Success",
        description: "Pick list cancelled successfully"
      });

      // Refresh pick lists
      fetchPickLists();
    } catch (error) {
      console.error('Error cancelling pick list:', error);

      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to cancel pick list"
      });
    }
  };

  const handlePrintAll = async (pickListId: string) => {
    try {
      setIsLoading(true);
      console.log('Starting print process...');

      const [labelsResponse, slipsResponse] = await Promise.all([
        pickListApi.printLabels(pickListId),
        pickListApi.printPackingSlips(pickListId)
      ]);

      if (slipsResponse instanceof Blob) {
        console.log('Creating blob URL...');
        const blobUrl = URL.createObjectURL(slipsResponse);
        window.open(blobUrl, '_blank');
      }

      if (labelsResponse instanceof Blob) {
        console.log('Creating blob URL...');
        const blobUrl = URL.createObjectURL(labelsResponse);
        window.open(blobUrl, '_blank');
      }

      toast({
        title: "Success",
        description: "Documents generated and orders moved to production",
      });

      await fetchPickLists();

    } catch (error) {
      console.error('Print error:', error);
      toast({
        variant: "destructive",
        title: "Error printing documents",
        description: "Failed to generate PDF documents. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getStatusBadge = (status: PickListStatus) => {
    switch (status) {
      case PickListStatus.PLANNED:
        return (
          <span className="px-2 py-1 text-sm rounded-full bg-yellow-100 text-yellow-800">
            Planned
          </span>
        );
      case PickListStatus.ACTIVE:
        return (
          <span className="px-2 py-1 text-sm rounded-full bg-green-100 text-green-800">
            Active
          </span>
        );
      case PickListStatus.COMPLETED:
        return (
          <span className="px-2 py-1 text-sm rounded-full bg-blue-100 text-blue-800">
            Completed
          </span>
        );
      case PickListStatus.CANCELLED:
        return (
          <span className="px-2 py-1 text-sm rounded-full bg-red-100 text-red-800">
            Cancelled
          </span>
        );
    }
  };

  return (
    <div className="space-y-6">
      {/* Create New Pick List */}
      <Card>
        <CardHeader>
          <CardTitle>Create New Pick List</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div className="flex items-center gap-4">
              <div className="text-sm text-gray-500">
                {selectedOrders.length} orders selected
              </div>
            </div>

            <PickListOrderSelector
              selectedOrders={selectedOrders}
              onOrdersSelected={setSelectedOrders}
            />

            {/* Planned Date Input and Create Button */}
            <div className="flex items-end justify-end">
              {/* Planned Date Input */}
              <div className="mr-6">
                <label htmlFor="plannedDate" className="block text-sm font-medium text-gray-700">
                  Planned Date and Time
                </label>
                <input
                  type="datetime-local"
                  id="plannedDate"
                  name="plannedDate"
                  value={plannedDate}
                  onChange={(e) => setPlannedDate(e.target.value)}
                  className="mt-1 block w-64 rounded-md border-gray-300 shadow-sm"
                />
              </div>

              {/* Create Pick List Button */}
              <Button
                onClick={handleCreatePickList}
                disabled={isCreating || selectedOrders.length === 0 || !plannedDate}
              >
                {isCreating ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Creating...
                  </>
                ) : (
                  <>
                    <Plus className="mr-2 h-4 w-4" />
                    Create Pick List ({selectedOrders.length} orders)
                  </>
                )}
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Planned Pick Lists */}
      <Card>
        <CardHeader>
          <CardTitle>Pick Lists</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {isLoading ? (
              <div className="flex justify-center p-4">
                <Loader2 className="w-6 h-6 animate-spin" />
              </div>
            ) : pickLists.length === 0 ? (
              <div className="text-center py-4 text-gray-500">
                No pick lists found
              </div>
            ) : (
              pickLists.map((pickList) => (
                <Card key={pickList.id}>
                  <CardContent className="p-4">
                    <div className="flex items-center justify-between">
                      <div>
                        <div className="flex items-center space-x-3">
                          <h3 className="font-medium">
                            Pick List #{pickList.sequence}
                          </h3>
                          {getStatusBadge(pickList.status)}
                        </div>
                        <p className="text-sm text-gray-500">
                          {pickList.orders?.length || 0} Orders
                        </p>
                        {pickList.plannedDate && (
                          <p className="text-sm text-gray-500">
                            Planned: {format(new Date(pickList.plannedDate), 'PPpp')}
                          </p>
                        )}
                      </div>
                      <div className="flex space-x-2">
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => handleViewOrders(pickList)}
                        >
                          <List className="w-4 h-4 mr-2" />
                          View Orders
                        </Button>
                        
                        {pickList.status === PickListStatus.ACTIVE && (
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => handlePrintAll(pickList.id)}
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <Loader2 className="h-4 w-4 animate-spin mr-2" />
                            ) : (
                              <Printer className="w-4 h-4 mr-2" />
                            )}
                            Print All
                          </Button>
                        )}

                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => handleCancelPickList(pickList.id)}
                          className="text-red-600 hover:text-red-800"
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))
            )}
          </div>
        </CardContent>
      </Card>

      {/* Orders Modal */}
      {selectedPickList && (
        <PickListOrdersModal
          pickList={selectedPickList}
          isOpen={isOrdersModalOpen}
          onClose={() => {
            setIsOrdersModalOpen(false);
            setSelectedPickList(null);
          }}
        />
      )}
    </div>
  );
};

export default AdminPickListManager;
