// packages/frontend/src/types/picklist.ts
import { Order, OrderStatus, OrderPriority } from './order';
import { CustomerType } from './customer';

export enum PickListStatus {
  PLANNED = 'PLANNED',
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED'
}

export interface PickListOrder {
  id: string;
  order: {
    id: string;
    orderNumber: string;
    status: OrderStatus;
    priority: OrderPriority;
    customer?: {
      name: string;
      email: string;
      orderCount?: number;
      customerType: CustomerType;
    };
    items: Array<{
      productTitle: string;
      variantTitle?: string;
      quantity: number;
    }>;
    deletedAt?: string;
  };
  status: OrderStatus;
  sequence: number;
  completedAt?: string;
}

export interface PickList {
  id: string;
  status: PickListStatus;
  sequence: number;
  orders: PickListOrder[];
  completedAt?: string;
  labelsPrinted?: string;
  slipsPrinted?: string;
  plannedDate: Date;
}

export interface PickListOrderSelectorProps {
  selectedOrders: Order[];
  onOrdersSelected: (orders: Order[]) => void;
}

export interface OrderFilters {
  dateRange?: {
    from: Date;
    to: Date;
  };
  priority?: OrderPriority;
  customerName?: string;
  orderNumber?: string;
  productTitle?: string;
}

export interface PickListSummaryProps {
  orders: PickListOrder[];
}
